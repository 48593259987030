:root {
    --scrollbar: 0;
    --header-height: 54px
}

*,
*::before,
*::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    letter-spacing: .04em
}

:where([hidden]:not([hidden=until-found])) {
    display: none !important
}

html {
    -webkit-text-size-adjust: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    scroll-padding-top: 80px
}

@media(prefers-reduced-motion: no-preference) {
    html {
        scroll-behavior: smooth
    }
}

@supports not (min-block-size: 100dvb) {
    html {
        block-size: 100%
    }
}

body {
    color: #000;
    font-family: YakuHanJP, "Noto Sans JP", sans-serif;
    font-weight: 500;
    font-size: .875rem;
    line-height: 1.5
}

main {
    overflow-x: hidden
}

:focus {
    outline: 0
}

:focus-visible {
    outline: 2px solid #538b21;
    outline-offset: 2px
}

:where(a, button, label, select, summary, [role=button], [role=option]) {
    cursor: pointer;
    touch-action: manipulation
}

a {
    color: inherit;
    background-color: rgba(0, 0, 0, 0);
    text-decoration: none
}

:where(button) {
    border: 0;
    background-color: rgba(0, 0, 0, 0)
}

:where(input, button, textarea, select) {
    font: inherit;
    color: inherit
}

input[type=text],
input[type=password],
input[type=email],
textarea {
    outline: none;
    border: none
}

input[type=checkbox],
input[type=radio],
input[type=range] {
    outline: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

input::-moz-placeholder,
textarea::-moz-placeholder {
    font-family: inherit;
    color: #a5a5a5
}

input::placeholder,
textarea::placeholder {
    font-family: inherit;
    color: #a5a5a5
}

:where(textarea) {
    resize: vertical;
    resize: block
}

:where(:disabled) {
    cursor: not-allowed
}

:where(label:has(>input:disabled), label:has(+input:disabled)) {
    cursor: not-allowed
}

:where(ul, ol) {
    list-style: none
}

:where(img, svg, video, canvas, audio, iframe, embed, object) {
    display: block
}

:where(img, picture, svg) {
    max-inline-size: 100%;
    block-size: auto;
    height: auto;
    vertical-align: bottom;
    width: 100%
}

:where(p) {
    letter-spacing: .028em
}

:where(h1, h2, h3, h4, h5, h6) {
    font-weight: 700;
    overflow-wrap: break-word;
    letter-spacing: .08em
}

:where(hr) {
    border: none;
    border-block-start: 1px solid;
    color: inherit;
    block-size: 0;
    overflow: visible
}

table {
    border-collapse: collapse
}

.modal {
    display: none;
    position: relative;
    z-index: 200
}

.modal.is-open {
    display: block
}

.modal[aria-hidden=false] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0.61, 1, 0.88, 1)
}

.modal[aria-hidden=false] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0.61, 1, 0.88, 1)
}

.modal[aria-hidden=true] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0.61, 1, 0.88, 1)
}

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(60, 60, 60, .7);
    display: grid;
    place-items: center
}

@keyframes mmfadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes mmslideIn {
    0% {
        transform: translateY(10%)
    }

    100% {
        transform: translateY(0)
    }
}

.appeal-point {
    display: flex;
    flex-flow: column;
    background-color: #dce8d2;
    padding: 20px;
    border-radius: 10px
}

.appeal-point p {
    position: relative;
    display: flex;
    align-items: center;
    font-size: .8125rem;
    font-weight: 700;
    line-height: 1.375;
    letter-spacing: .05em;
    padding-left: 15px
}

.appeal-point p:before {
    content: "";
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    top: .66em;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 4px;
    background-color: #6eb92b
}

.approach-bottom {
    width: 100%;
    border-radius: 10px;
    overflow: hidden
}

.approach-bottom__bar {
    display: block;
    width: 100%;
    height: 8px;
    background-color: #6eb92b
}

.approach-bottom-head {
    position: relative;
    width: 100%;
    padding: 30px 20px;
    background-color: #fff
}

.approach-bottom-head__ttl {
    color: #6eb92b;
    font-size: 1.5rem;
    font-weight: 900;
    line-height: 1.5;
    letter-spacing: .04em
}

.approach-bottom-head__ttl--min {
    font-size: 1.0625rem;
    margin-left: 2px
}

.approach-bottom-head__human {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 146px;
    height: auto;
    overflow: hidden
}

.approach-bottom-head__human-img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.approach-bottom-desc {
    background-color: #e8f2df;
    padding: 20px;
    border-radius: 0 0 10px 10px
}

.approach-bottom-desc__txt {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.64;
    letter-spacing: .04em
}

.approach-bottom-desc__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    color: #fff;
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .1em;
    background-color: #6eb92b
}

.approach-bottom-desc__btn:after {
    content: "";
    display: inline-block;
    width: 5px;
    height: 8.34px;
    background-image: url(/assets/renewal/smp/img/icon/arrow_white_right02.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 1px;
    margin-left: 10px
}

.assessable-figure__item {
    margin-top: 14.5px;
    border-radius: 10px;
    position: relative
}

.assessable-figure__item--ttl {
    position: absolute;
    display: inline-block;
    height: 29px;
    border-radius: 3px;
    background-color: #616161;
    padding: 0 20px;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .04em;
    line-height: 27px;
    color: #fff
}

.assessable-figure__greenbox {
    border: solid 3px #6eb92b;
    border-radius: 10px;
    padding: 34px 29px 20px;
    height: auto;
    position: relative
}

.assessable-figure__greenbox-wrap {
    display: flex;
    justify-content: center;
    gap: 20px
}

.assessable-figure__greenbox--ttl {
    font-size: .8125rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: .04em;
    position: relative;
    padding-left: 7px
}

.assessable-figure__greenbox--ttl:before {
    content: "";
    display: inline-block;
    position: absolute;
    transform: translate(-4px, -3px);
    top: 50%;
    left: 0;
    width: 6px;
    height: 6px;
    background-color: #6eb92b;
    border-radius: 50%
}

.assessable-figure__greenbox--text {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: .04em;
    position: relative;
    padding-left: 10px
}

.assessable-figure__greenbox--text:before {
    content: "・";
    display: inline-block;
    width: .7em
}

.assessable-figure__greenbox--note {
    font-size: .6875rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .04em;
    position: relative;
    padding-left: 16px
}

.assessable-figure__greenbox--deco {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7.5px
}

.assessable-figure__greenbox--deco p {
    text-align: center;
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .05em;
    color: #6eb92b
}

.assessable-figure__greenbox--deco:before {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(/assets/renewal/smp/img/icon/sell/assessable/arrow-top.webp);
    background-size: contain;
    background-repeat: no-repeat
}

.assessable-figure__greenbox--deco:after {
    content: "";
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(/assets/renewal/smp/img/icon/sell/assessable/arrow-down.webp);
    background-size: contain;
    background-repeat: no-repeat
}

.assessable-figure__bullet--ttl {
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.6;
    letter-spacing: .04em;
    color: #6eb92b;
    margin-bottom: 10px
}

.assessable-figure__bullet--text {
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1.6;
    letter-spacing: .04em;
    position: relative;
    padding-left: 1em
}

.assessable-figure__bullet--text:before {
    content: "・";
    position: absolute;
    transform: translate(0, -1em);
    top: 1em;
    left: 0
}

.banner-salepoint {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6eb92b;
    width: 100%;
    height: 120px;
    border-radius: 10px
}

.banner-salepoint__img {
    width: 150px;
    height: auto;
    margin-bottom: 10px
}

.banner-salepoint__txt {
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .03em;
    line-height: 1.625;
    margin-left: -10px
}

.banner-salepoint__txt--yel {
    color: #f8ff40
}

.banner--lg {
    width: 100%
}

.border-ttl {
    padding-left: 10px;
    font-size: .9375rem;
    font-weight: 700;
    line-height: 1.5;
    position: relative;
    border-left: 4px solid #6eb92b;
    padding-left: 6px
}

.breadcrumb-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px 0;
    padding: 15px 20px
}

.breadcrumb-list__item {
    font-size: .625rem;
    font-weight: 500
}

.breadcrumb-list__item-arrow {
    display: inline-block;
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_right.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 4px;
    height: 7px;
    margin-inline: 10px
}

.button-lg {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 50px;
    border-radius: 6px
}

.button-lg--green {
    background-color: #6eb92b
}

.button-lg--white {
    background-color: #fff
}

.button-lg__search {
    width: 24px;
    height: auto
}

.button-lg__store {
    width: 35px;
    height: auto
}

.button-lg__arrow {
    width: 8px;
    height: auto
}

.button-lg__txt {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .04em
}

.button-lg__txt--white {
    color: #fff
}

.button-lg__txt--green {
    color: #6eb92b
}

.check-desc__ttl {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    font-weight: 700
}

.check-desc__ttl span {
    content: url(/assets/renewal/smp/img/icon/circle-check.svg);
    width: 30px;
    height: 30px
}

.check-desc__txt {
    font-size: 1em;
    font-weight: 500;
    line-height: 1.64285714;
    letter-spacing: .04em;
    text-align: justify
}

.check-point {
    display: flex;
    flex-flow: column
}

.check-point__head {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #6eb92b;
    font-size: .9375rem;
    font-weight: 700;
    letter-spacing: .1em
}

.check-point__head span {
    content: url(/assets/renewal/smp/img/icon/point-hand.svg);
    width: 44px;
    height: 44px
}

.check-point__head.ls-04 {
    letter-spacing: .04em
}

.check-point__detail {
    display: flex;
    flex-flow: column;
    border: 3px solid #6eb92b;
    border-radius: 10px;
    padding: 20px
}

.check-point__detail-ttl {
    display: flex;
    align-items: baseline;
    gap: 4px;
    font-size: .875rem;
    font-weight: 700
}

.check-point__detail-ttl span {
    color: #6eb92b;
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: 1.125rem;
    letter-spacing: 0
}

.check-point__detail-txt {
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1.61;
    letter-spacing: .04em;
    margin-top: 12px;
    text-align: justify
}

.check-point__detail-right {
    width: 100%;
    height: auto;
    border-radius: 10px
}

.check-point__detail-right img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    border-radius: 10px
}

.column {
    flex-flow: column
}

.cont-wrap {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px
}

.cont-wrap--green {
    background-color: #6eb92b
}

.cont-wrap--blue {
    background-color: #01a3d7
}

.cont-bottom {
    padding: 20px;
    border-radius: 10px 10px 0 0
}

.cont__ttl {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .1em;
    margin-bottom: 15px
}

.cont__ttl--green {
    color: #6eb92b
}

.cont__ttl-icon {
    width: 30px;
    height: 30px
}

.cont__ttl-icon--wide {
    width: 42px;
    height: 29px
}

.cont__ttl.fs18 {
    font-size: 1.125rem
}

.cont__txt {
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .04em;
    height: 1.64285714
}

.cont__txt-wrap {
    color: #6eb92b;
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.285714;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 3px
}

.cont__txt-wrap--blue {
    color: #01a3d7
}

.cont__txt--green {
    color: #6eb92b
}

.cont__txt--blue {
    color: #01a3d7
}

.cont-unk-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    width: -moz-fit-content;
    width: fit-content;
    margin-inline: auto
}

.cont-unk-btn__txt {
    font-size: .8125rem;
    letter-spacing: .04em;
    font-weight: 700
}

.cont-unk-btn svg {
    width: 21px;
    height: 21px
}

.swiper-wrap {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px 0
}

.swiper-wrap--green {
    background-color: #6eb92b
}

.swiper-wrap--bottom {
    padding-bottom: 0
}

.swiper__ttl {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .1em;
    margin-bottom: 15px
}

.swiper__ttl-icon {
    width: 30px;
    height: 30px
}

.swiper__txt {
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .04em;
    height: 1.64285714;
    margin-bottom: 16px
}

.swiper__txt-wrap {
    color: #6eb92b;
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.285714;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #fff;
    padding: 5px 10px;
    border-radius: 3px
}

.swiper__txt--green {
    color: #6eb92b
}

.cta01__inner {
    display: flex;
    flex-flow: column;
    height: 266px;
    border-radius: 10px;
    background: linear-gradient(#DCE8D2 255px, #6eb92b 0%)
}

.cta01__top {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -10px
}

.cta01__img {
    width: 109px;
    height: auto
}

.cta01__img img {
    width: 100%;
    height: auto;
    -o-object-fit: contain;
    object-fit: contain
}

.cta01__main {
    display: flex;
    flex-flow: column;
    align-items: center
}

.cta01__main--ttl {
    font-size: .8125rem;
    font-weight: 700;
    letter-spacing: .04em;
    line-height: 1.5;
    margin-top: 10px
}

.cta01__link {
    border-radius: 10px;
    border: 3px solid #f28f08;
    background-color: #f28f08;
    color: #fff;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center
}

.cta01__link .txt {
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .04em;
    display: flex;
    align-items: center;
    gap: 10px
}

.cta01__link .txt svg {
    width: 28px;
    height: 28px
}

.cta01__link .cls-3,
.cta01__link .cls-4 {
    transition: stroke .3s ease
}

.cta01 .normal-note {
    font-weight: 500;
    line-height: 1.4545
}

.doclibray-index-list {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.doclibray-index-list__item-wrap {
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px
}

.doclibray-index-list__item-wrap::after {
    position: absolute;
    right: 0;
    content: url(/assets/renewal/smp/img/icon/circle-arrow.svg);
    width: 20px;
    height: 20px;
    display: block;
    flex-shrink: 0
}

.doclibray-index-list__item a {
    height: 100%;
    padding: 20px;
    border-radius: 10px;
    background-color: #fff;
    display: flex;
    flex-flow: column;
    position: relative
}

.doclibray-index-list__item a:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 6px;
    border-radius: 10px 10px 0 0;
    background-color: #6eb92b;
    position: absolute;
    top: 0;
    left: 0
}

.doclibray-index-list__illust {
    width: 90px;
    flex-shrink: 0;
    flex-grow: 0
}

.doclibray-index-list__tags {
    display: flex;
    -moz-column-gap: 5px;
    column-gap: 5px;
    align-items: center
}

.doclibray-index-list__tag {
    width: -moz-fit-content;
    width: fit-content;
    line-height: 20px;
    font-size: .75rem;
    display: inline-flex;
    padding-left: 5px;
    padding-right: 5px;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    border: 1px solid #6eb92b;
    border-radius: 3px;
    font-weight: 700;
    color: #6eb92b
}

.doclibray-index-list__tag .en {
    font-family: "Roboto", "Noto Sans JP", sans-serif
}

.doclibray-index-list__ttl {
    line-height: 1.2;
    margin-top: 6px;
    font-size: .875rem;
    letter-spacing: .04em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 700
}

.doclibray-index-list__ttl--bottom {
    align-items: flex-end
}

.doclibray-index-list__txt {
    font-size: .75rem;
    letter-spacing: .04em;
    margin-top: 20px;
    text-align: justify
}

.doclibray-index-list__txt-area {
    width: 100%
}

.faq-acc a {
    text-decoration: underline
}

.faq-acc__item {
    background-color: #f5f5f5;
    padding: 10px 20px;
    border-radius: 10px
}

.faq-acc__item button {
    width: 100%
}

.faq-acc__item+.faq-acc__item {
    margin-top: 10px
}

.faq-acc__item.open .faq-acc__icn::after {
    opacity: 0
}

.faq-acc__content {
    width: 100%
}

.faq-acc__content--q {
    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px
}

.faq-acc__content--a {
    align-items: flex-start;
    margin-top: 10px;
    padding-top: 10px;
    border-top: 2px solid #ccc;
    transition: border-color .3s
}

.faq-acc__q-head {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 1;
    color: #616161
}

.faq-acc__q-txt {
    font-weight: 700;
    font-size: .8125rem;
    text-align: justify
}

.faq-acc__a-txt {
    display: inline-block;
    line-height: 1.57;
    font-size: .75rem;
    text-align: justify
}

.faq-acc__icn {
    flex-shrink: 0;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 10px;
    height: 10px;
    margin-left: auto
}

.faq-acc__icn::before,
.faq-acc__icn::after {
    content: "";
    position: absolute;
    top: 50;
    left: 0;
    width: 100%;
    height: 3px;
    background: #6eb92b;
    border-radius: 99px
}

.faq-acc__icn::after {
    transform: rotate(90deg);
    transition: opacity .3s
}

.faq-acc--blue .faq-acc__icn {
    flex-shrink: 0;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 10px;
    height: 10px;
    margin-left: auto
}

.faq-acc--blue .faq-acc__icn::before,
.faq-acc--blue .faq-acc__icn::after {
    content: "";
    position: absolute;
    top: 50;
    left: 0;
    width: 100%;
    height: 3px;
    background: #01a3d7;
    border-radius: 99px
}

.faq-acc--blue .faq-acc__icn::after {
    transform: rotate(90deg);
    transition: opacity .3s
}

.faq-tab {
    width: 100%
}

.faq-tab__item-head {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 6px
}

.faq-tab__item-head-icon {
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #6eb92b
}

.faq-tab__item-head-txt {
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .1em
}

.tab__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    margin-bottom: 20px
}

.tab__menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    line-height: 1.25;
    text-align: center;
    width: calc(25% - 1.5px);
    height: 54px;
    border: 2px solid #6eb92b;
    border-radius: 6px;
    background-color: #6eb92b;
    transition: all .3s
}

.tab__menu-item.is-active {
    color: #6eb92b;
    background-color: #fff
}

.tab__panel {
    width: 100%;
    background-color: #fff;
    border-radius: 9px
}

.tab__panel-box001,
.tab__panel-box002,
.tab__panel-box003,
.tab__panel-box004 {
    overflow: hidden;
    height: 0;
    opacity: 0
}

.tab__panel-box.is-show {
    overflow: visible;
    height: auto;
    opacity: 1;
    transition: opacity .6s ease-in-out
}

.faq-wrap {
    width: 100%;
    padding: 20px;
    background-color: #fff
}

.faq-acc__q-txt {
    font-size: .8125rem;
    font-weight: 500;
    line-height: 1.61538
}

.fixed-btn {
    position: fixed;
    bottom: -1px;
    display: flex;
    gap: 4px;
    width: 100%;
    background-color: #fff;
    z-index: 12;
    padding: 5px;
    transition: .2s
}

.fixed-btn-item {
    display: flex;
    background-color: #6eb92b;
    width: calc(33.33% - 2px);
    border-radius: 4px;
    transition: all .2s
}

.fixed-btn-item--blue {
    background-color: #01a3d7
}

.fixed-btn-item__box {
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    width: 100%;
    font-size: 11px;
    line-height: 1.181818;
    text-align: left;
    padding: 11px 6px;
    border: 3px solid #6eb92b;
    border-radius: 4px
}

.fixed-btn-item__box--blue {
    border: 3px solid #01a3d7
}

.fixed-btn-item:nth-child(1) .fixed-btn-item__box:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(/assets/renewal/smp/img/shop/fixed01.svg)
}

.fixed-btn-item:nth-child(2) .fixed-btn-item__box:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(/assets/renewal/smp/img/shop/fixed02.svg)
}

.fixed-btn-item:nth-child(3) .fixed-btn-item__box:before {
    content: "";
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(/assets/renewal/smp/img/shop/fixed03.svg)
}

.change .fixed-btn-item.change-bg {
    background-color: #fff
}

.change .fixed-btn-item__box.change-btn {
    color: #6eb92b
}

.change .fixed-btn-item__box--blue.change-btn {
    color: #01a3d7
}

.change .fixed-btn-item:nth-child(2) .fixed-btn-item__box:before {
    background-image: url(/assets/renewal/smp/img/shop/fixed02-green.svg)
}

.change .fixed-btn-item--blue:nth-child(2) .fixed-btn-item__box--blue:before {
    background-image: url(/assets/renewal/smp/img/shop/fixed02-blue.svg)
}

.fixed-btn.hide {
    opacity: 0;
    pointer-events: none
}

.modal {
    position: relative;
    z-index: 11
}

.modal__overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px
}

.modal__container {
    width: 100%
}

.modal__close {
    position: absolute;
    top: -50px;
    right: 0;
    background: rgba(0, 0, 0, 0);
    border: 0
}

.modal__content {
    position: relative;
    line-height: 1.5;
    color: rgba(0, 0, 0, .8)
}

@keyframes mmfadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: 1
    }
}

@keyframes mmfadeOut {
    from {
        opacity: 1
    }

    to {
        opacity: 0
    }
}

@keyframes mmslideIn {
    from {
        transform: translateY(15%)
    }

    to {
        transform: translateY(0)
    }
}

@keyframes mmslideOut {
    from {
        transform: translateY(0)
    }

    to {
        transform: translateY(-10%)
    }
}

.micromodal-slide {
    display: none
}

.micromodal-slide.is-open {
    display: block
}

.micromodal-slide[aria-hidden=false] .modal__overlay {
    animation: mmfadeIn .3s cubic-bezier(0, 0, 0.2, 1)
}

.micromodal-slide[aria-hidden=false] .modal__container {
    animation: mmslideIn .3s cubic-bezier(0, 0, 0.2, 1)
}

.micromodal-slide[aria-hidden=true] .modal__overlay {
    animation: mmfadeOut .3s cubic-bezier(0, 0, 0.2, 1)
}

.micromodal-slide[aria-hidden=true] .modal__container {
    animation: mmslideOut .3s cubic-bezier(0, 0, 0.2, 1)
}

.micromodal-slide .modal__container,
.micromodal-slide .modal__overlay {
    will-change: transform
}

.modal-interview {
    display: flex;
    flex-flow: column;
    gap: 5px;
    width: 100%
}

.modal-interview__link {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%
}

.modal-interview__item {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    background-color: #fff;
    padding-inline: 20px
}

.modal-interview__item-num {
    color: #6eb92b;
    width: 18px;
    font-size: 15px;
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1;
    margin-top: 2px
}

.modal-interview__item-num--blue {
    color: #01a3d7
}

.modal-interview__item-branch {
    width: 85px;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1;
    margin-top: -2px
}

.modal-interview__item-name {
    font-size: 15px;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1;
    margin-top: -2px
}

.modal-interview__item-icon {
    width: 5px;
    height: 8px;
    margin-bottom: -2px;
    margin-left: auto
}

.feature-swiper .swiper-slide {
    border-radius: 10px;
    -o-object-fit: cover;
    object-fit: cover;
    isolation: isolate;
    overflow: hidden;
    width: 230px
}

.feature-swiper .swiper-slide a {
    display: flex;
    flex-direction: column;
    height: 100%
}

.feature-swiper .swiper-slide a:active .feature-swiper__txt {
    background-color: #e8f2df
}

.feature-swiper__img-wrap {
    aspect-ratio: 230/126;
    overflow: hidden
}

.feature-swiper__img-wrap img {
    -o-object-fit: cover;
    object-fit: cover;
    width: 100%;
    height: 100%;
    pointer-events: none
}

.feature-swiper__txt {
    flex: 1;
    background-color: #fff;
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    min-height: 70px;
    padding: 6px 20px;
    font-size: 1rem;
    transition: background-color .3s
}

.feature-swiper__txt .txt {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2
}

.feature-swiper__txt::after {
    content: url(/assets/renewal/smp/img/icon/circle-arrow.svg);
    width: 20px;
    height: 20px;
    display: block
}

.fixed-banner-input {
    position: fixed;
    left: 8px;
    bottom: 8px;
    width: calc(100% - 16px);
    height: 50px;
    background-color: #f28f08;
    z-index: 5;
    border-radius: 6px;
    opacity: 1;
    pointer-events: initial;
    transition: .3s
}

.fixed-banner-input svg {
    width: 18px;
    height: 18px
}

.fixed-banner-input-link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 100%
}

.fixed-banner-input-link__txt {
    color: #fff;
    font-size: 1rem;
    font-weight: 700;
    letter-spacing: .1em
}

.fixed-banner-input.hide {
    opacity: 0;
    pointer-events: none
}

.fixed-banner-input.hide-2 {
    opacity: 0;
    pointer-events: none
}

.fixed-banner {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: 3;
    transition: all .6s
}

.fixed-banner img {
    width: 225px
}

.fixed-banner.hide {
    visibility: hidden;
    opacity: 0
}

.fixed-banner.hide-2 {
    visibility: hidden;
    opacity: 0
}

.flex-banner {
    display: flex;
    flex-wrap: wrap;
    gap: 10px
}

.flex-banner__item {
    width: calc(50% - 5px)
}

.flow-list__flow {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 5px
}

.flow-list__flowitem {
    position: relative;
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 26px 20px;
    flex: 1
}

.flow-list__flowitem--left {
    width: 58px;
    height: 40px
}

.flow-list__flowitem--left img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.flow-list__flowitem--right {
    flex: 1
}

.flow-list__flowitem--right .flow-list__flowitem--ttl {
    font-size: 14px;
    margin-bottom: 2px
}

.flow-list__flowitem--right .flow-list__flowitem--text {
    font-size: 12px
}

.flow-list__flowitem-arrow {
    width: 26px;
    height: 10px
}

.flow-list__flowitem--num {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #6eb92b;
    color: #fff;
    border-radius: 10px 0 10px 0;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    padding: 0 14.5px
}

.flow-list__flowitem--ttl {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .05em;
    margin-bottom: 10px;
    color: #6eb92b
}

.flow-list__flowitem--text {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64
}

.wreath-flow .flow-list__flowitem {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 20px
}

.footer {
    background-color: #6eb92b;
    color: #fff;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 17px;
    position: relative
}

.footer__links {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px
}

.footer__link {
    line-height: 1;
    font-size: .75rem;
    letter-spacing: 0;
    font-weight: 700;
    padding-left: 24px;
    padding-right: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(/assets/renewal/smp/img/icon/arrow_white_right.svg);
    background-repeat: no-repeat;
    background-size: 6px 10px;
    background-position: right 10px center;
    background-color: rgba(255, 255, 255, .2);
    letter-spacing: 0;
    height: 42px;
    border-radius: 5px;
    transition: background-color .3s
}

.footer__link:active {
    background-color: rgba(255, 255, 255, .1)
}

.footer__link.footer__link--tab {
    -moz-column-gap: 10px;
    column-gap: 10px
}

.footer__link.footer__link--tab::after {
    content: url(/assets/renewal/smp/img/icon/tab-link_white.svg);
    display: block;
    width: 12px;
    height: 12px
}

.footer__txt {
    font-size: .6875rem;
    margin-top: 20px;
    line-height: 1.45
}

.footer__copyright {
    background-color: #6eb92b;
    font-size: .75rem;
    margin-top: 36px
}

.footer__totop {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 50px;
    height: 50px;
    background-color: #fff;
    border-top-left-radius: 3px;
    display: grid;
    place-items: center
}

.footer__totop .icon {
    width: 19px
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    z-index: 10
}

.header__bar {
    padding-left: 15px;
    height: var(--header-height);
    background-color: #fff;
    display: flex;
    align-items: center;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .16)
}

.header__logo {
    margin-right: auto;
    width: 93px
}

.header__btn-menu {
    display: flex;
    -moz-column-gap: 12px;
    column-gap: 12px;
    margin-right: 12px
}

.header__btn-menu svg {
    margin-inline: auto;
    width: 22px
}

.header__btn-menu .item {
    text-align: center
}

.header__btn-menu .item a {
    height: var(--header-height);
    width: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center
}

.header__btn-menu .txt {
    letter-spacing: 0;
    display: inline-block;
    margin-top: 1px;
    font-size: .625rem
}

.heading-h1 {
    font-size: .6875rem;
    font-weight: 500;
    letter-spacing: .04em;
    padding-block: 7px
}

.inpage-links {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 15px
}

.inpage-links.four-column {
    grid-template-columns: repeat(4, 1fr)
}

.inpage-links__item a {
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(/assets/renewal/smp/img/icon/circle-arrow_bottom_sm.svg);
    background-repeat: no-repeat;
    background-size: 16px 16px;
    background-position: center right 15px;
    background-position-y: center;
    height: 60px;
    border-radius: 5px;
    letter-spacing: .1em;
    font-size: 1rem;
    font-weight: 700
}

.inpage-links.flex-column {
    display: flex
}

.inpage-links.flex-column .inpage-links__item {
    flex: 1
}

.intro-nav {
    position: relative;
    z-index: 0;
    margin-top: -20px
}

.intro-nav::before {
    z-index: -1;
    margin-top: 20px;
    position: absolute;
    top: 0;
    content: "";
    display: block;
    width: 100%;
    height: 270px;
    background-color: #6eb92b
}

.intro-nav__history {
    background-color: #fff;
    display: block;
    border-radius: 6px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    padding-right: 52px;
    position: relative
}

.intro-nav__history::after {
    content: url(/assets/renewal/smp/img/icon/circle-arrow.svg);
    width: 20px;
    height: 20px;
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%)
}

.intro-nav__history-top {
    font-size: .875rem;
    font-weight: 700;
    letter-spacing: .1em;
    background-image: url(/assets/renewal/smp/img/icon/search.svg);
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 18px 18px;
    padding-left: 23px
}

.intro-nav__history-top .count {
    font-size: 0
}

.intro-nav__history-top .num {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: 1.25rem;
    color: #6eb92b;
    letter-spacing: .02em
}

.intro-nav__history-top .sm {
    font-size: .75rem
}

.intro-nav__history-btm {
    font-size: .8125rem;
    letter-spacing: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.intro-nav__col3-link {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    -moz-column-gap: 6px;
    column-gap: 6px;
    margin-top: 8px
}

.intro-nav__col3-link-item a {
    background-color: #fff;
    font-size: .9375rem;
    letter-spacing: .1em;
    display: block;
    border-radius: 5px;
    overflow: hidden
}

.intro-nav__col3-link-item a .icon {
    width: 42px;
    margin-top: 4px
}

.intro-nav__col3-link-arrow {
    background-color: #e8f2df;
    display: grid;
    place-items: center;
    height: 20px
}

.intro-nav__col3-link-arrow img {
    width: 10px
}

.intro-nav__txt-area {
    padding: 12px;
    font-size: .9375rem;
    letter-spacing: .1em;
    font-weight: 700;
    display: grid;
    place-items: center
}

.intro-nav__btm-link {
    margin-top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    background-color: #fff;
    letter-spacing: .1em;
    font-size: .9375rem;
    font-weight: 700;
    height: 54px;
    border-radius: 6px
}

.intro-nav__btm-link img {
    width: 35px
}

.kv {
    position: relative
}

.kv__txtbox {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    padding-left: 15px;
    color: #fff
}

.kv__txtbox .txt {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .1em
}

.kv__txtbox .ttl {
    font-size: 20px;
    font-weight: 700;
    line-height: 1.4;
    letter-spacing: .1em;
    margin-top: 5px
}

.kv__txtbox .ttl small {
    font-size: 16px
}

.library-cont__thumb {
    width: 100%;
    border-radius: 10px;
    overflow: hidden
}

.library-cont__thumb img {
    width: 100%;
    height: 100%;
    -o-object-fit: contain;
    object-fit: contain
}

.library-cont__txt {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.6428;
    letter-spacing: .04em;
    text-align: justify
}

.link-box-lgreen {
    position: relative;
    background-color: #e8f2df;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 20px
}

.link-box-lgreen-wrap {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.link-box-lgreen p {
    text-align: left;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.428;
    letter-spacing: .04em
}

.link-box-lgreen:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-color: #292929;
    width: 100%;
    height: 100%;
    border-radius: 10px;
    opacity: 0;
    transition: opacity .3s ease
}

.link-box-lgreen:after {
    content: url(/assets/renewal/smp/img/icon/circle-arrow.svg);
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    display: block
}

.link-box-lgreen.link-box-lgreen-not:before,
.link-box-lgreen.link-box-lgreen-not:after {
    display: none
}

.link-btn {
    font-size: 1rem;
    font-weight: 700;
    -moz-column-gap: 6px;
    column-gap: 6px;
    display: flex;
    align-items: center;
    width: -moz-fit-content;
    width: fit-content;
    position: relative;
    transition: color .3s;
    margin-inline: auto
}

.link-btn::before {
    content: "";
    position: absolute;
    right: 0;
    top: 50%;
    background-color: #292929;
    width: 20px;
    height: 20px;
    border-radius: 10px;
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity .3s ease
}

.link-btn:after {
    content: url(/assets/renewal/smp/img/icon/circle-arrow.svg);
    width: 20px;
    height: 20px;
    display: block
}

.link-btn.fs13 {
    font-size: .8125rem;
    letter-spacing: .04em
}

.link-btn.fs14 {
    font-size: .875rem;
    letter-spacing: .1em
}

.link-btn.fs16 {
    font-size: 1rem;
    letter-spacing: .1em
}

.link-btns {
    position: relative;
    z-index: 1;
    display: grid;
    gap: 5px
}

.link-btns+.link-btns {
    margin-top: 5px
}

.link-btns._top {
    margin-top: -30px
}

.link-btns.link-btns--col1 {
    grid-template-columns: repeat(1, 1fr)
}

.link-btns.link-btns--col1 .icon {
    width: 56px
}

.link-btns.link-btns--col2 {
    grid-template-columns: repeat(2, 1fr)
}

.link-btns.link-btns--col2 .icon {
    width: 42px
}

.link-btns.link-btns--col3 {
    grid-template-columns: repeat(3, 1fr)
}

.link-btns.link-btns--col3 .link-btns__item a {
    flex-direction: column;
    padding-right: 0
}

.link-btns.link-btns--col3 .link-btns__item a .icon {
    width: 33px
}

.link-btns__item a {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    background-color: #fff;
    font-size: 1rem;
    height: 80px;
    border-radius: 6px;
    letter-spacing: .06em;
    font-weight: 700;
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_right.svg);
    background-size: 6px 10px;
    background-position: right 10px center;
    background-repeat: no-repeat;
    padding-right: 18px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .15)
}

.link-btns__item a .icon {
    width: 42px
}

.link-btns__item a:active {
    background-color: #e8f2df
}

.menu-btn {
    width: 54px;
    height: 54px;
    background-color: #6eb92b
}

.menu-btn__inner {
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-column-gap: 10px;
    column-gap: 10px
}

.menu-btn .line {
    display: grid;
    row-gap: 8px;
    width: 25px;
    margin-left: 1px
}

.menu-btn .line::before,
.menu-btn .line::after {
    display: block;
    width: 100%;
    height: 3px;
    border-radius: 999px;
    background-color: #fff;
    content: "";
    transition: all .3s
}

.menu-btn[aria-expanded=true] .line::before {
    transform: translateY(6px) rotate(45deg)
}

.menu-btn[aria-expanded=true] .line::after {
    transform: translateY(-5px) rotate(-45deg)
}

.menu {
    position: fixed;
    top: var(--header-height);
    left: 0;
    z-index: 5;
    overflow: auto;
    height: calc(100svh - var(--header-height));
    width: 100vw;
    background-color: #f0f0f0;
    opacity: 0;
    display: none;
    flex-direction: column;
    justify-content: space-between
}

.menu__quick-search {
    background-color: #6eb92b;
    padding-top: 20px;
    padding-bottom: 10px
}

.menu__quick-search-top {
    display: grid;
    align-items: center;
    grid-template-columns: max-content 1fr;
    -moz-column-gap: 20px;
    column-gap: 20px
}

.menu__quick-search-txt {
    color: #fff;
    letter-spacing: .2em;
    font-size: .9375rem;
    background-image: url(/assets/renewal/smp/img/icon/search_white.svg);
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 22px 22px;
    padding-left: 32px
}

.menu__quick-search-txt.letter-md {
    letter-spacing: .1em
}

.menu__quick-search-word {
    display: flex;
    align-items: center;
    height: 50px;
    background-color: #fff;
    border-radius: 6px;
    padding-left: 20px;
    padding-right: 5px;
    -moz-column-gap: 10px;
    column-gap: 10px;
    margin-top: 10px
}

.menu__quick-search-word input[type=text] {
    height: 100%;
    flex: 1;
    color: #292929;
    font-size: 1rem
}

.menu__quick-search-word .btn {
    flex-shrink: 0;
    width: 70px;
    height: 40px;
    display: grid;
    place-items: center;
    background-color: #6eb92b;
    color: #fff;
    border-radius: 5px
}

.menu__center {
    padding-top: 12px;
    padding-bottom: 15px;
    width: 100%;
    flex: 1
}

.menu__center-item+.menu__center-item {
    margin-top: 8px
}

.menu__center-item.open .btn-icon::after {
    opacity: 0
}

.menu__btn {
    background-color: #6eb92b;
    color: #fff;
    font-size: 1rem;
    letter-spacing: .1em;
    position: relative;
    display: flex;
    align-items: center;
    -moz-column-gap: 9px;
    column-gap: 9px;
    padding: 10px 20px;
    height: 50px;
    width: 100%;
    border-radius: 5px
}

.menu__btn .btn-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px
}

.menu__btn--acc .btn-icon::before,
.menu__btn--acc .btn-icon::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background: #fff;
    border-radius: 99px
}

.menu__btn--acc .btn-icon::after {
    transform: rotate(90deg);
    transition: opacity .3s
}

.menu__btn--link .btn-icon::before {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/arrow_white_right.svg);
    background-repeat: no-repeat;
    background-size: 7px 11px;
    background-position: center;
    width: 7px;
    height: 11px;
    display: block
}

.menu__btn--dark {
    background-color: #538b21;
    letter-spacing: .04em
}

.menu__btn .icon {
    width: 34px
}

.menu__center-sub-item:first-child {
    margin-top: 6px
}

.menu__center-sub-item+.menu__center-sub-item {
    margin-top: 4px
}

.menu__center-sub-item a {
    background-color: #fff;
    font-size: 1rem;
    letter-spacing: .1em;
    position: relative;
    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 10px 20px;
    height: 50px;
    width: 100%;
    border-radius: 5px
}

.menu__center-sub-item a .btn-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 10px;
    height: 10px
}

.menu__center-sub-item a .btn-icon::before {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_right.svg);
    background-repeat: no-repeat;
    background-size: 7px 11px;
    background-position: center;
    width: 7px;
    height: 11px;
    display: block
}

.menu__center-sub-item--tab a {
    -moz-column-gap: 10px;
    column-gap: 10px
}

.menu__center-sub-item--tab a::after {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/tab-link_green.svg);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center;
    display: block;
    width: 12px;
    height: 12px
}

.more-btn {
    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    font-size: 1rem;
    font-weight: 700
}

.more-btn svg {
    width: 20px
}

.mv {
    position: relative;
    z-index: 0
}

.mv .swiper-pagination {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-column-gap: 8px;
    column-gap: 8px
}

.mv .swiper-pagination .swiper-pagination-bullet {
    border-radius: 50%;
    display: block;
    width: 6px;
    height: 6px;
    background-color: #fff
}

.mv .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #6eb92b
}

.cm-mv .swiper-pagination {
    position: relative;
    bottom: initial;
    left: initial;
    transform: initial;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-column-gap: 8px;
    column-gap: 8px
}

.new-props {
    display: flex;
    flex-wrap: nowrap;
    gap: 10px;
    width: -moz-fit-content;
    width: fit-content;
    padding-left: 35px;
    transform: translate(-20px, 0)
}

.new-props-wrap {
    width: 100%;
    overflow-x: scroll
}

.new-props-wrap::-webkit-scrollbar {
    display: none
}

.new-props-item {
    position: relative;
    display: flex;
    flex-flow: column;
    width: 168px;
    height: auto;
    border-radius: 6px
}

.new-props-item__link {
    width: 100%;
    height: 100%
}

.new-props-item__icon {
    position: absolute;
    top: 10px;
    left: 10px;
    display: flex;
    gap: 5px
}

.new-props-item__icon--left {
    height: 16px;
    color: #fff;
    font-size: 10px;
    background-color: #6eb92b;
    border-radius: 3px;
    padding-inline: 5px;
    line-height: 15px
}

.new-props-item__icon--right {
    height: 16px;
    color: #538b21;
    font-size: 10px;
    border: 1px solid #538b21;
    background-color: #fff;
    padding-inline: 5px;
    line-height: 14px;
    border-radius: 8px
}

.new-props-item__thumb {
    width: 100%;
    height: auto;
    border-radius: 6px 6px 0 0;
    overflow: hidden
}

.new-props-item__thumb-img {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
    object-fit: cover
}

.new-props-item__info {
    padding: 10px;
    background-color: #fff;
    border-radius: 0 0 6px 6px
}

.new-props-item__info-ttl {
    font-size: .75rem;
    font-weight: 700;
    line-height: 1.333;
    letter-spacing: .03em;
    margin-bottom: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 1px solid #dbdbdb
}

.new-props-item__info-list {
    display: flex;
    flex-flow: column;
    gap: 4px
}

.new-props-item__info-list-tag {
    display: flex;
    align-items: center;
    gap: 5px
}

.new-props-item__info-list-tag-icon {
    width: 16px;
    height: 16px
}

.new-props-item__info-list-tag-txt {
    font-size: .625rem;
    font-weight: 500;
    letter-spacing: .04em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.news__item+.news__item {
    margin-top: 16px
}

.news__meta {
    display: flex;
    align-items: center;
    -moz-column-gap: 1em;
    column-gap: 1em
}

.news__time {
    flex-shrink: 0;
    line-height: 1;
    font-size: .9375rem;
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-weight: 700;
    color: #538b21
}

.news__tag {
    font-weight: bold;
    height: 1.5em;
    flex-shrink: 0;
    border-radius: 999px;
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
    padding-left: .2em;
    padding-right: .2em;
    line-height: 1;
    display: grid;
    place-items: center;
    min-width: 4.66666em;
    font-size: .75rem;
    color: #fff
}

.news__tag.news__tag--new {
    background-color: #6eb92b
}

.news__tag.news__tag--important {
    background-color: #f28f08
}

.news__txt {
    flex: 1;
    letter-spacing: .05em;
    margin-top: 5px;
    color: #292929
}

.normal-note {
    font-size: .6875rem;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    text-align: justify
}

.normal-note.refmark {
    padding-left: 1.5em;
    position: relative
}

.normal-note.refmark:before {
    content: "※";
    position: absolute;
    left: 0;
    top: 0
}

.normal-note.fs13 {
    font-size: 13px
}

.pickup-swiper .swiper-slide {
    width: 320px
}

.pickup-swiper .swiper-pagination {
    margin-top: 16px;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    -moz-column-gap: 10px;
    column-gap: 10px
}

.pickup-swiper .swiper-pagination .swiper-pagination-bullet {
    border-radius: 50%;
    display: block;
    width: 6px;
    height: 6px;
    background-color: #bababa
}

.pickup-swiper .swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: #6eb92b
}

.point-ttl {
    font-size: .875rem;
    display: flex;
    align-items: center;
    gap: 6px;
    font-weight: 700
}

.point-ttl.point-ttl--lg {
    font-size: 1.125rem
}

.point-ttl::before {
    content: "";
    display: block;
    width: 4px;
    height: 4px;
    border-radius: 1px;
    background-color: #6eb92b
}

.pointservice__top {
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 390/430;
    margin-top: 54px
}

.pointservice__top--bg {
    position: absolute;
    width: 100%;
    left: 0;
    max-inline-size: none
}

.pointservice__top--txtbox {
    position: absolute;
    top: 8vw;
    left: 0;
    padding-inline: 15px
}

.pointservice__top--txtbox h1 {
    width: 91.7948717949vw;
    height: 24.1025641026vw
}

.pointservice__top--txtbox p {
    margin-top: 2.1333333333vw;
    font-size: 3.7333333333vw;
    font-weight: 700;
    letter-spacing: .04em;
    line-height: 1.71;
    color: #fff
}

.pointservice__unique-cont {
    margin-top: -30px;
    z-index: 2;
    position: relative
}

.pointservice__banner {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    padding: 30px 20px;
    border-radius: 10px
}

.pointservice__banner .card {
    width: 100%;
    max-width: 166px;
    height: auto;
    border-radius: 10px;
    margin-bottom: 10px
}

.pointservice__banner .cont {
    margin-bottom: 20px
}

.pointservice__banner .cont p {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.333;
    text-align: center
}

.pointservice__banner .button {
    width: 100%;
    border-radius: 6px;
    display: flex;
    align-items: center;
    gap: 10px;
    background-color: #6eb92b;
    color: #fff;
    padding: 10px 18px;
    margin-top: 16px
}

.pointservice__banner .button__arrow {
    width: 6px;
    height: 10px
}

.pointservice__banner .button span {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .1em;
    display: inline-block;
    padding-bottom: 2px
}

.pointservice__banner .button .a,
.pointservice__banner .button .b {
    fill: #fff
}

.pointservice__banner .result {
    width: 100%;
    max-width: 170px
}

.pointservice__flexcard {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.pointservice__card {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 30px 10px 10px
}

.pointservice__card--head {
    display: flex;
    gap: 16px
}

.pointservice__card--num {
    width: 42px;
    height: 42px;
    border-radius: 6px;
    background-color: #6eb92b;
    color: #fff;
    font-size: 16px;
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 43px;
    text-align: center
}

.pointservice__card--ttl {
    color: #6eb92b;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .04em;
    line-height: 1.4;
    flex: 1
}

.pointservice__card--img {
    width: 100%;
    max-width: 174px;
    height: auto;
    margin-inline: auto;
    margin-top: 27px;
    margin-bottom: 24px
}

.pointservice__card--message {
    background-color: #fff;
    border-radius: 10px;
    height: auto;
    display: flex;
    align-items: center;
    padding: 20px
}

.pointservice__card--message p {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .04em;
    line-height: 1.61
}

.pointservice__bordersec {
    border: 3px solid #6eb92b;
    border-radius: 10px;
    padding: 30px 18px 20px;
    text-align: center
}

.pointservice__bordersec.pb10 {
    padding-bottom: 10px
}

.pointservice__bordersec--ttl {
    font-size: 16px;
    font-weight: 900;
    letter-spacing: .03em;
    border-bottom: 2px solid #6eb92b;
    color: #6eb92b;
    display: inline-block
}

.pointservice__bordersec--catch {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.64;
    margin-top: 20px
}

.pointservice__bordersec--catch span {
    color: #6eb92b
}

.pointservice__bordersec--note {
    font-size: 11px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.45;
    margin-top: 7px;
    text-align: left
}

.pointservice__bordersec--figure {
    width: auto;
    margin-top: 40px
}

.pointservice__bordersec--figure.width850 {
    display: inline-block;
    width: 850px;
    margin-inline: auto
}

.pointservice__figure01 {
    display: flex;
    width: 100%
}

.pointservice__figure01--head {
    gap: 4px;
    display: flex;
    flex-flow: column
}

.pointservice__figure01--head li {
    background-color: #6eb92b;
    color: #fff;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .01em;
    height: 70px;
    border-radius: 5px 0 0 5px;
    padding: 9px 5px;
    writing-mode: vertical-rl;
    text-orientation: upright
}

.pointservice__figure01--head li:first-child {
    height: 92px
}

.pointservice__figure01--table {
    width: 100%;
    border-spacing: 0 10px
}

.pointservice__figure01--table tbody {
    display: flex;
    gap: 5px;
    height: 314px
}

.pointservice__figure01--table tr {
    display: flex;
    flex-flow: column;
    background-color: #f5f5f5;
    border-radius: 3px;
    width: 100%
}

.pointservice__figure01--table tr:first-child {
    border-radius: 0 3px 3px 0
}

.pointservice__figure01--table tr td {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
    width: -webkit-fill-available;
    height: 74px
}

.pointservice__figure01--table tr td:first-child {
    height: 92px
}

.pointservice__figure01--table tr td:first-child .passege {
    margin-left: 6px
}

.pointservice__figure01--table tr td:not(:first-child) p {
    display: flex;
    flex-flow: column
}

.pointservice__figure01--table tr td:not(:first-child) .en {
    margin-bottom: -3px
}

.pointservice__figure01--table tr td .en {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: 18px;
    font-weight: 900;
    letter-spacing: .01em;
    color: #6eb92b
}

.pointservice__figure01--table tr td .passege {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: .01em;
    display: inline-block;
    color: #616161
}

.pointservice__figure01--table tr td small {
    display: inline-block;
    margin-left: 2px;
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .05em;
    color: #616161
}

.pointservice__figure01--table tr td:not(:last-child) {
    position: relative
}

.pointservice__figure01--table tr td:not(:last-child):after {
    content: "";
    width: 40px;
    background-color: #e5e5e5;
    border-radius: 3px;
    position: absolute;
    left: 50%;
    bottom: -1px;
    height: 2px;
    transform: translate(-50%, 0)
}

.pointservice__figure02 {
    width: 100%;
    margin-inline: auto;
    display: flex;
    flex-flow: column;
    gap: 5px;
    align-items: center
}

.pointservice__figure02--left {
    width: 100%;
    max-width: 160px;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 15px 22px;
    display: flex;
    gap: 13px;
    align-items: center
}

.pointservice__figure02--left img {
    width: 45px
}

.pointservice__figure02--left p {
    font-size: 13px;
    letter-spacing: .1em;
    font-weight: 700
}

.pointservice__figure02--deco {
    transform: rotate(90deg)
}

.pointservice__figure02--deco svg {
    width: 14px;
    height: 35px
}

.pointservice__figure02--right {
    flex: 1
}

.pointservice__figure02--right div {
    background-color: #f5f5f5;
    border-radius: 10px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .04em;
    padding: 16px 20px;
    text-align: left
}

.pointservice__figure02--right div span {
    color: #6eb92b
}

.pointservice__figure02--right div:not(:last-child) {
    margin-bottom: 10px
}

.pointservice__links {
    background-color: #6eb92b;
    border-radius: 10px;
    padding-block: 20px;
    display: flex;
    flex-flow: column
}

.pointservice__links--left {
    display: flex;
    flex-flow: column;
    position: relative
}

.pointservice__links--left:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    height: 3px;
    width: 100%;
    border-radius: 2px;
    background-color: #fff;
    opacity: .3
}

.pointservice__links--right {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px
}

.pointservice__links .largebtn {
    background-color: #fff;
    color: #6eb92b;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .04em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 13px 16px;
    border-radius: 10px;
    position: relative
}

.pointservice__links .largebtn:after {
    transition: opacity .3s;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 10px;
    background-color: rgba(41, 41, 41, .16);
    opacity: 0
}

.pointservice__links .largebtn .deco {
    width: 6px;
    height: 10px;
    margin-left: 8px;
    margin-top: 2px
}

.pointservice__links .smallbtn {
    width: 100%;
    background-color: #fff;
    color: #6eb92b;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    border-radius: 6px;
    position: relative
}

.pointservice__links .smallbtn:after {
    transition: opacity .3s;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 6px;
    background-color: rgba(41, 41, 41, .16);
    opacity: 0
}

.pointservice__links .smallbtn .deco {
    width: 6px;
    height: 10px;
    margin-left: 10px
}

.prefecture-links.open .prefecture-links__btn--head {
    background-color: #e5e5e5
}

.prefecture-links.open .prefecture-links__btn--head .btn-icon::after {
    opacity: 0
}

.prefecture-links__item {
    margin-top: 8px
}

.prefecture-links__item--top {
    margin-top: 0
}

.prefecture-links__item.open .btn-icon::after {
    opacity: 0
}

.prefecture-links__btn {
    font-size: 1rem;
    letter-spacing: .1em;
    position: relative;
    display: flex;
    align-items: center;
    -moz-column-gap: 9px;
    column-gap: 9px;
    padding: 10px 20px;
    height: 50px;
    width: 100%;
    border-radius: 5px
}

.prefecture-links__btn .btn-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 10px;
    height: 10px
}

.prefecture-links__btn--acc {
    background-color: #e8f2df
}

.prefecture-links__btn--acc .btn-icon::before,
.prefecture-links__btn--acc .btn-icon::after {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    background: #6eb92b;
    border-radius: 99px
}

.prefecture-links__btn--acc .btn-icon::after {
    transform: rotate(90deg);
    transition: opacity .3s
}

.prefecture-links__btn--acc.prefecture-links__btn--head {
    background-color: #fff
}

.prefecture-links__btn--link {
    background-color: #fff
}

.prefecture-links__btn--link .btn-icon::before {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_right.svg);
    background-repeat: no-repeat;
    background-size: 7px 11px;
    background-position: center;
    width: 7px;
    height: 11px;
    display: block
}

.prefecture-links__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
    margin-top: 6px
}

.prefecture-links__content-item a {
    background-color: #fff;
    font-size: 1rem;
    letter-spacing: .1em;
    position: relative;
    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 10px 20px;
    height: 50px;
    width: 100%;
    border-radius: 5px
}

.prefecture-links__content-item a .btn-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 10px;
    height: 10px
}

.prefecture-links__content-item a .btn-icon::before {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_right.svg);
    background-repeat: no-repeat;
    background-size: 7px 11px;
    background-position: center;
    width: 7px;
    height: 11px;
    display: block
}

.prefecture-links__content-item--tab a {
    -moz-column-gap: 10px;
    column-gap: 10px
}

.prefecture-links__content-item--tab a::after {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/tab-link_green.svg);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center;
    display: block;
    width: 12px;
    height: 12px
}

.prefecture-links--blue .prefecture-links__btn--acc {
    background-color: #ddf7ff
}

.prefecture-links--blue .prefecture-links__btn--acc .btn-icon::before,
.prefecture-links--blue .prefecture-links__btn--acc .btn-icon::after {
    background: #01a3d7
}

.prefecture-links--blue .prefecture-links__btn.prefecture-links__btn--head {
    background-color: #fff
}

.prefecture-links--blue .prefecture-links__btn--link .btn-icon::before {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/arrow_blue_right.svg);
    background-repeat: no-repeat;
    background-size: 7px 11px;
    background-position: center;
    width: 7px;
    height: 11px;
    display: block
}

.prefecture-links--blue .prefecture-links__content {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 6px;
    margin-top: 6px
}

.prefecture-links--blue .prefecture-links__content-item a {
    background-color: #fff;
    font-size: 1rem;
    letter-spacing: .1em;
    position: relative;
    display: flex;
    align-items: center;
    -moz-column-gap: 10px;
    column-gap: 10px;
    padding: 10px 20px;
    height: 50px;
    width: 100%;
    border-radius: 5px
}

.prefecture-links--blue .prefecture-links__content-item a .btn-icon {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    width: 10px;
    height: 10px
}

.prefecture-links--blue .prefecture-links__content-item a .btn-icon::before {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/arrow_blue_right.svg);
    background-repeat: no-repeat;
    background-size: 7px 11px;
    background-position: center;
    width: 7px;
    height: 11px;
    display: block
}

.prefecture-links--blue .prefecture-links__content-item--tab a {
    -moz-column-gap: 10px;
    column-gap: 10px
}

.prefecture-links--blue .prefecture-links__content-item--tab a::after {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/tab-link_green.svg);
    background-repeat: no-repeat;
    background-size: 12px 12px;
    background-position: center;
    display: block;
    width: 12px;
    height: 12px
}

.primary-heading {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 180px;
    padding-top: 50px
}

.primary-heading--rent {
    background-image: url(/assets/renewal/smp/img/rent-bg.webp)
}

.primary-heading--buy {
    background-image: url(/assets/renewal/smp/img/buy-bg.webp)
}

.primary-heading--lend {
    background-image: url(/assets/renewal/smp/img/lend-bg.webp)
}

.primary-heading__ttl {
    border-left: 8px solid #6eb92b;
    padding-left: 18px;
    line-height: 25px;
    letter-spacing: .1em;
    font-size: 1.25rem
}

.primary-heading__ttl .sm {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    letter-spacing: .05em;
    font-weight: 700;
    color: #6eb92b;
    font-size: .75rem
}

.support .tab-list {
    position: relative;
    z-index: 3;
    margin-top: -50px
}

.support .tab-list__menu {
    padding-inline: 10px
}

.support .tab-list__menu-item {
    font-size: 13px
}

.support .tab-list__menu-item--ls3 {
    letter-spacing: .3em
}

.support .tab-list__panel {
    border: none;
    border-top: 3px solid #6eb92b;
    padding: 20px 20px 0;
    border-radius: 0
}

.support .faq-acc__content--a {
    padding-bottom: 10px
}

.support .faq-acc__item {
    background-color: #fff
}

.support .faq-acc__a-txt {
    display: inline-block;
    font-weight: 400
}

.support .faq-acc__a-txt--bold {
    font-weight: 700
}

.support .faq-acc__a-txt--sm {
    display: inline-block;
    font-size: 11px;
    font-weight: 500;
    line-height: 1.7;
    letter-spacing: .04em
}

.support .faq-acc__a-img {
    width: 100%;
    height: auto
}

.support .faq-acc__a-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    height: 40px;
    border-radius: 6px;
    background-color: #6eb92b;
    color: #fff;
    font-size: 14px;
    line-height: 1.1666;
    letter-spacing: .1em;
    text-decoration: none
}

.support .faq-acc__a-btn:after {
    content: "";
    display: inline-block;
    width: 6px;
    height: 10px;
    background-image: url(/assets/renewal/smp/img/icon/arrow_white_right.svg);
    background-size: contain;
    background-repeat: no-repeat;
    margin-top: 2px
}

.support-anc {
    display: flex;
    flex-wrap: wrap;
    gap: 9px
}

.support-anc-item {
    width: calc(50% - 4.5px);
    height: 40px;
    border-radius: 5px;
    background-color: #f0f0f0
}

.support-anc-item__link {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 5px;
    font-size: 13px;
    padding-inline: 10px
}

.support-anc-item__link:after {
    content: "";
    display: inline-block;
    width: 21px;
    height: 21px;
    background-image: url(/assets/renewal/smp/img/icon/circle-arrow-bottom.webp);
    background-size: contain;
    background-repeat: no-repeat
}

.support-faq {
    width: 100%;
    border-radius: 10px;
    background-color: #e8f2df;
    padding: 20px
}

.support-faq__ttl {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-bottom: 14px
}

.support-faq__ttl-icon {
    width: 25px;
    height: 25px
}

.support-faq__ttl-txt {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em
}

.support-faq__btn {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 13px;
    letter-spacing: .04em
}

.support-faq__btn:after {
    content: "";
    display: inline-block;
    width: 21px;
    height: 21px;
    background-image: url(/assets/renewal/smp/img/icon/circle-arrow-up.webp);
    background-size: contain;
    background-repeat: no-repeat
}

.quick-search {
    position: relative
}

.quick-search select {
    display: none
}

.quick-search.open .quick-search__select-output {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

.quick-search__select-output {
    text-align: left;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    max-width: 100%;
    line-height: 50px;
    background-color: #fff;
    color: #292929;
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    border-radius: 5px
}

.quick-search__select-output::before {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_btm.svg);
    background-repeat: no-repeat;
    pointer-events: none;
    width: 10px;
    height: 6px
}

.quick-search__select {
    z-index: 2;
    position: absolute;
    background-color: #f5f5f5;
    color: #000;
    top: 50px;
    left: 0;
    width: 100%;
    display: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .1);
    overflow-y: auto;
    max-height: 210px
}

.quick-search__select .item {
    font-size: .875rem;
    letter-spacing: .03em;
    padding: 14px 20px;
    min-height: 47px
}

.quick-search__select .item:not(:last-child) {
    border-bottom: 1px solid #e5e5e5
}

.rent-sale-navi {
    position: relative;
    z-index: 1
}

.rent-sale-navi__heading {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    font-weight: 700
}

.rent-sale-navi__body {
    background-color: #fff;
    padding: 20px
}

.rent-sale-navi__links {
    display: flex;
    flex-flow: column;
    gap: 5px;
    margin-top: 15px
}

.rent-sale-navi__link {
    height: 60px
}

.rent-sale-navi__link-wrap {
    display: flex;
    gap: 5px
}

.rent-sale-navi__link.arrow--none a:after {
    content: none
}

.rent-sale-navi__link a {
    height: 100%;
    border-radius: 6px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: .875rem;
    padding-left: 10px;
    position: relative
}

.rent-sale-navi__link a .txt {
    font-weight: 700
}

.rent-sale-navi__link a .txt.two-characters {
    width: -moz-max-content;
    width: max-content;
    text-align: center;
    letter-spacing: .4em;
    margin-left: 18px
}

.rent-sale-navi__link a:after {
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translate(0, -50%);
    content: "";
    display: inline-block;
    width: 6px;
    height: 10px;
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_right-lg.svg);
    background-size: contain;
    background-repeat: no-repeat
}

.rent-sale-navi__link a svg.right-top {
    position: absolute;
    top: 13px;
    left: 128px;
    width: 10px;
    height: 10px
}

.rent-sale-navi__link.lg {
    width: 100%
}

.rent-sale-navi__link.lg a {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: row;
    gap: 10px
}

.rent-sale-navi__link.lg a svg {
    width: 56px;
    height: 40px
}

.rent-sale-navi__link.md {
    width: calc(50% - 2.5px)
}

.rent-sale-navi__link.md a {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: row;
    gap: 6px
}

.rent-sale-navi__link.md svg {
    width: 35px;
    height: 25px
}

.rent-sale-navi__link.sm {
    width: calc(33.33% - 1.7px)
}

.rent-sale-navi__link.sm a {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 5px;
    padding-left: 0
}

.rent-sale-navi__link.sm svg {
    width: 33px;
    height: 23px
}

.rent-sale-navi__ttl {
    font-size: .9375rem;
    font-weight: 700;
    padding-left: 10px;
    position: relative;
    letter-spacing: .2em
}

.rent-sale-navi__ttl::before {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-color: #6eb92b;
    content: "";
    width: 4px;
    height: 15px
}

.rent-sale-navi__person {
    position: absolute;
    bottom: 0;
    right: 28px;
    width: 315px
}

.request-btn {
    display: flex;
    align-items: center;
    background-color: #fff;
    padding: 10px 40px 10px 10px;
    font-size: 1rem;
    letter-spacing: .1em;
    font-weight: 700;
    position: relative;
    border-radius: 5px;
    background-repeat: no-repeat;
    background-image: url(/assets/renewal/smp/img/icon/circle-arrow.svg);
    background-position: right 15px center;
    background-size: 20px 20px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .15)
}

.request-btn__img-area {
    background-color: #f0f0f0;
    height: 60px;
    width: 80px;
    display: grid;
    place-items: center;
    border-radius: 5px;
    margin-right: 15px
}

.request-btn__img-area img {
    width: 50px
}

.sale-p-heading {
    background-color: #e8f2df;
    padding: 35px 0 247px
}

.salepoint-intro {
    display: flex;
    flex-flow: column
}

.salepoint-intro__head {
    width: 100%
}

.salepoint-intro__head--ttl {
    width: 100%
}

.salepoint-intro__head--ttl img {
    width: 100%
}

.salepoint-intro__head--txt {
    color: #292929;
    font-size: .875rem;
    font-weight: 700;
    line-height: 1.64;
    letter-spacing: .04em
}

.salepoint-intro__step {
    display: flex;
    gap: 8px
}

.salepoint-intro__step--item {
    position: relative;
    width: calc(33.33% - 4px)
}

.salepoint-intro__step--item img {
    width: 100%;
    height: 100%
}

.salepoint-intro__step--item p {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    font-size: .75rem;
    font-weight: 700;
    width: -moz-max-content;
    width: max-content;
    text-align: center;
    letter-spacing: .2em
}

.salepoint-intro__step--item:last-child {
    margin-right: 0
}

.sale-flow__head {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .1em
}

.sale-flow__head img {
    width: 42px;
    height: 29px;
    display: inline-block
}

.sale-flow__anchores {
    display: flex;
    flex-wrap: wrap;
    gap: 9px
}

.sale-flow__anchores--item {
    border-radius: 5px;
    background-color: #fff;
    width: calc(50% - 4.5px);
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    text-align: left;
    position: relative;
    padding-inline: 10px
}

.sale-flow__anchores--item-num {
    color: #6eb92b;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.4
}

.sale-flow__anchores--item-txt {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.15;
    display: inline-block
}

.sale-flow__anchores--item:after {
    content: "";
    display: block;
    position: absolute;
    background-image: url(/assets/renewal/smp/img/icon/circle-arrow_bottom_sm.svg);
    background-size: cover;
    width: 16px;
    height: 16px;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%)
}

.sale-flow__linkbtn {
    background-color: #6eb92b;
    border-radius: 6px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: #fff;
    padding: 10px 25px;
    width: 100%;
    height: 40px;
    transition: background-color .5s ease;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.5
}

.sale-flow__linkbtn-icon {
    width: 6px;
    height: 11px
}

.sale-flow-step {
    padding-block: 20px
}

.sale-flow-step__head {
    display: flex;
    gap: 14px;
    justify-content: space-between
}

.sale-flow-step__head--left {
    width: 100%
}

.sale-flow-step__head--thumb {
    width: 100%;
    height: auto;
    border-radius: 10px
}

.sale-flow-step__ttl {
    display: flex;
    align-items: center;
    gap: 14px
}

.sale-flow-step__ttl img {
    height: 41px;
    width: 40px;
    padding-right: 10px;
    border-right: 1px solid #6eb92b
}

.sale-flow-step__ttl h2 {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: .1em;
    color: #6eb92b
}

.sale-flow-step__catchbox {
    margin-top: 20px
}

.sale-flow-step__catchbox--catch {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .1em
}

.sale-flow-step__catchbox--catch.sm {
    font-size: 16px;
    letter-spacing: .05em
}

.sale-flow-step__catchbox--message {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.7;
    text-align: justify;
    margin-top: 10px
}

.sale-flow-step__links {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.sale-flow-step__links--link {
    background-color: #6eb92b;
    border-radius: 6px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #fff;
    padding: 10px 20px;
    height: 40px;
    border: 2px solid #6eb92b;
    transition: background-color .3s ease, color .3s ease;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.5
}

.sale-flow-step__point {
    padding: 28px 18px;
    border: 3px solid #6eb92b;
    border-radius: 10px
}

.sale-flow-step__point--ttl {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .1em;
    color: #6eb92b
}

.sale-flow-step__point--ttl-icon {
    width: 44px;
    height: 44px;
    display: inline-block
}

.sale-flow-step__point--list.grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr
}

.sale-flow-step__point--list.grid li {
    margin-top: 0
}

.sale-flow-step__point--list li {
    padding-left: 12px;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.6
}

.sale-flow-step__point--list li:before {
    content: "";
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    top: .8em;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 4px;
    background-color: #6eb92b
}

.sale-flow-step__point--list li:not(:first-child) {
    margin-top: 10px
}

.sale-flow-step__point--list li .small-list {
    transform: translateX(-11px);
    padding-left: 13px
}

.sale-flow-step__point--list li .small-list p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    position: relative;
    padding-left: 1em
}

.sale-flow-step__point--list li .small-list p:before {
    content: "・";
    top: .8em;
    left: 0;
    transform: translate(0, -50%);
    position: absolute
}

.sale-flow-step__flex01 {
    display: flex;
    gap: 50px
}

.sale-flow-step__flex01--text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.7;
    margin-top: 13px;
    text-align: justify
}

.sale-flow-step__flex01 img {
    width: 430px
}

.sale-flow-step__table {
    border: 2px solid #f5f5f5;
    border-bottom: none;
    display: flex;
    flex-flow: column
}

.sale-flow-step__table--column {
    flex: 1
}

.sale-flow-step__tableitem {
    display: flex
}

.sale-flow-step__tableitem.type01 {
    height: auto
}

.sale-flow-step__tableitem.type02 {
    height: auto
}

.sale-flow-step__tableitem.type03 {
    height: auto
}

.sale-flow-step__tableitem.type04 {
    height: auto
}

.sale-flow-step__tableitem--ttl {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 60px;
    background-color: #e8f2df;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .04em;
    border-bottom: 2px solid #fff
}

.sale-flow-step__tableitem--cont {
    padding: 11px 10px;
    flex: 1;
    display: grid;
    align-items: center;
    border-bottom: 2px solid #f5f5f5
}

.sale-flow-step__tableitem--normal {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.5
}

.sale-flow-step__tableitem--note {
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .03em
}

.sale-flow-step__tableitem--list li {
    padding-left: 15px;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.2
}

.sale-flow-step__tableitem--list li:before {
    content: "";
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    top: .66em;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 4px;
    background-color: #6eb92b
}

.sale-flow-step__tableitem--list li:not(:first-child) {
    margin-top: 10px
}

.sale-flow-step__tableitem--list li span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .04em;
    margin-top: 5px
}

.sale-flow-step__tableitem:last-child .sale-flow-step__tableitem--ttl {
    border-bottom: 2px solid #f5f5f5
}

.sale-flow-step__borderbox01 {
    border: 3px solid #6eb92b;
    border-radius: 10px
}

.sale-flow-step__borderbox01--flex {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 10px;
    padding: 30px 20px
}

.sale-flow-step__borderbox01--flex.gap60 {
    gap: 60px
}

.sale-flow-step__borderbox01--flex p {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .1em
}

.sale-flow-step__borderbox02 {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 30px 20px;
    text-align: justify;
    line-height: 1.6
}

.sale-flow-step__borderbox02 .border-bottom {
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 30px
}

.sale-flow-step__borderbox02--ttl {
    color: #6eb92b;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .04em
}

.sale-flow-step__borderbox02--text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .04em
}

.sale-flow-step__borderbox02--check {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.sale-flow-step__borderbox02--check li {
    display: flex;
    gap: 10px
}

.sale-flow-step__borderbox02--check li p {
    flex: 1;
    font-size: 13px;
    font-weight: 700
}

.sale-flow-step__borderbox02--check li span {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid #dbdbdb;
    background-color: #fff;
    border-radius: 3px
}

.sale-flow-step__flow {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 5px
}

.sale-flow-step__flowitem {
    position: relative;
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 26px 20px;
    width: 100%
}

.sale-flow-step__flowitem-arrow {
    width: 26px;
    height: 10px
}

.sale-flow-step__flowitem--num {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #6eb92b;
    color: #fff;
    border-radius: 10px 0 10px 0;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    padding: 0 14.5px
}

.sale-flow-step__flowitem--ttl {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .05em;
    margin-bottom: 10px;
    color: #6eb92b
}

.sale-flow-step__flowitem--text {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64
}

.sale-flow-step__prepare {
    border-radius: 10px;
    background-color: #e8f2df;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    gap: 20px
}

.sale-flow-step__prepare--ttl {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #538b21;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.42
}

.sale-flow-step__prepare--ttl img {
    width: 30px;
    height: 30px
}

.sale-flow-step__prepare--list {
    align-self: flex-start
}

.sale-flow-step__prepare--list li {
    padding-left: 13px;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.5
}

.sale-flow-step__prepare--list li:before {
    content: "";
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    top: .7em;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 4px;
    background-color: #6eb92b
}

.sale-flow-step__prepare--list li:not(:first-child) {
    margin-top: 10px
}

.sale-flow-step__flex02 {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.sale-flow-step__flex02--item {
    border-radius: 10px;
    border: 3px solid #6eb92b;
    display: flex;
    align-items: center;
    padding: 16px 0;
    flex: 1
}

.sale-flow-step__flex02--ttl {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.375em;
    color: #6eb92b;
    text-align: center;
    padding: 0 20px
}

.sale-flow-step__flex02--list {
    flex: 1;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    padding-left: 20px;
    border-left: 2px solid #f0f0f0
}

.sale-flow-step__flex03 {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.sale-flow-step__flex03--item {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 20px;
    flex: 1
}

.sale-flow-step__flex03--ttl {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.375em;
    color: #6eb92b;
    margin-bottom: 10px
}

.sale-flow-step__flex03--list {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    padding-left: 20px
}

.sale-flow-step__flowcolumn3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 62px 48px
}

.sale-flow-step__flowcolumn3 .sale-flow-step__flowitem:after {
    display: none
}

.sale-flow-step__flowcolumn3 .sale-flow-step__flowitem:not(:nth-child(3n)):after {
    display: block;
    right: -33px
}

.sale-flow-step__flowcolumn3 .sale-flow-step__flowitem:last-child:after {
    display: none
}

.sale-flow-step__flowcolumn3 .sale-flow-step__flowitem:nth-child(3):before {
    content: url(/assets/renewal/smp/img/icon/deco/flow-rows-arrow.svg);
    right: 50%;
    bottom: -9px;
    transform: translate(0, 100%);
    position: absolute
}

.sale-flow-step__bigarrow {
    width: 35px;
    margin-inline: auto
}

.search-input--input {
    position: relative;
    background: #fff;
    border-radius: 10px;
    width: 100%;
    z-index: 2
}

.search-input--input:has(>.open) {
    border-radius: 10px 10px 0 0
}

.search-input--input .title {
    font-size: 16px;
    color: #fff;
    font-weight: bold;
    background: #6eb92b;
    border-radius: 10px 10px 0 0;
    padding: 8px 45px;
    text-align: center
}

.search-input--input .inputbox {
    padding: 0 20px 20px 20px
}

.search-input--input .inputbox .step {
    display: flex;
    align-items: center;
    gap: 18px;
    margin: 18px 0 10px
}

.search-input--input .inputbox .step .left {
    display: flex;
    align-items: flex-end;
    gap: 3px;
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    color: #6eb92b;
    font-size: 14px;
    line-height: 1;
    letter-spacing: 0px
}

.search-input--input .inputbox .step .left span {
    font-size: 24px;
    line-height: .8;
    letter-spacing: 0px;
    margin-top: -5px
}

.search-input--input .inputbox .step .right {
    font-size: 14px;
    font-weight: bold;
    color: #292929;
    margin-bottom: 3px
}

.search-input--input .inputbox .top-quick-search__select-wrap {
    position: relative
}

.search-input--input .inputbox .top-quick-search__select-wrap button {
    background: #f5f5f5;
    text-align: left;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 13px 0 13px 30px;
    border-radius: 6px;
    position: relative
}

.search-input--input .inputbox .top-quick-search__select-wrap button::after {
    content: "";
    background: url(/assets/renewal/smp/img/icon/lendtop/lend-select-arrow.svg);
    width: 14px;
    height: 9px;
    background-repeat: no-repeat;
    display: inline-block;
    position: absolute;
    right: 20px;
    transform: translateY(-50%);
    top: 50%
}

.search-input--input .inputbox .top-quick-search__select-wrap select {
    background: #f5f5f5;
    text-align: left;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    padding: 13px 0 13px 30px;
    border-radius: 6px;
    position: relative
}

.search-input--input .inputbox .top-quick-search__select-wrap .selectbox {
    position: relative;
    width: 100%;
    height: 50px;
    display: block
}

.search-input--input .inputbox .top-quick-search__select-wrap .selectbox:before {
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_under.svg);
    background-repeat: no-repeat;
    position: absolute;
    width: 10px;
    height: 6px;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
    pointer-events: none
}

.search-input--input .inputbox .top-quick-search__select-wrap ul {
    max-height: 210px;
    font-size: 16px;
    font-weight: bold;
    overflow-y: scroll;
    position: absolute;
    width: 100%;
    background: #fff;
    z-index: 99;
    box-shadow: 0 3px 6px rgba(0, 0, 0, .1);
    border-radius: 0 0 10px 10px
}

.search-input--input .inputbox .top-quick-search__select-wrap ul::-webkit-scrollbar {
    border-radius: 3.75px;
    width: 7.5px
}

.search-input--input .inputbox .top-quick-search__select-wrap ul::-webkit-scrollbar-track {
    border-radius: 3.75px
}

.search-input--input .inputbox .top-quick-search__select-wrap ul::-webkit-scrollbar-thumb {
    border-radius: 3.75px;
    background-color: #bababa
}

.search-input--input .inputbox .top-quick-search__select-wrap ul li {
    padding: 13px 30px;
    border-bottom: 1px solid #e5e5e5
}

.search-input--input .inputbox .top-quick-search__select-wrap.open button {
    border-radius: 6px 6px 0 0
}

.search-input--input .inputbox .step2 {
    border-top: 2px solid #ccc;
    margin-top: 20px;
    padding-top: 18px
}

.search-input--input .inputbox label:nth-of-type(3) select {
    margin: 10px 0
}

.search-input--input .found {
    background: #71bf38;
    border-radius: 0 0 10px 10px;
    padding: 20px 30px 30px 30px;
    position: absolute;
    bottom: 0px;
    z-index: -1;
    transition: all .5s;
    width: -webkit-fill-available;
    opacity: 0
}

.search-input--input .found.open {
    position: initial;
    opacity: 1
}

.search-input--input .found__area {
    color: #fff;
    font-size: 16px;
    font-weight: bold
}

.search-input--input .found__contactbtn {
    display: flex;
    background: #f28f08;
    border-radius: 6px;
    justify-content: center;
    padding: 13px 0 16px;
    gap: 10px;
    border: 2px solid #fff;
    margin-top: 16px;
    align-items: center;
    transition: all .3s
}

.search-input--input .found__contactbtn .left {
    padding-top: 4px
}

.search-input--input .found__contactbtn .left svg {
    width: 26px;
    height: 21px;
    color: #fff;
    transition: all .3s
}

.search-input--input .found__contactbtn .middle {
    color: #fff;
    font-size: 18px;
    font-weight: bold;
    transition: all .3s
}

.search-input--input .found__contactbtn .right {
    color: #f28f08;
    background: #fff;
    font-size: 14px;
    padding: 3px 12px 2px;
    border-radius: 30px;
    transition: all .3s
}

.search-input--input .found__number {
    background: #fff;
    display: flex;
    border-radius: 6px;
    padding: 9px 0;
    align-items: center;
    gap: 10px;
    justify-content: center;
    margin-top: 10px
}

.search-input--input .found__number .middle {
    font-size: 20px;
    font-family: "Roboto", "Noto Sans JP", sans-serif
}

.search-input--input .found__number .right {
    color: #fff;
    background: #6eb92b;
    font-size: 14px;
    padding: 3px 12px 4px;
    border-radius: 30px
}

.search-input--input #found:target {
    bottom: -200px
}

.search-input--input .notfound {
    background: #538b21;
    width: -webkit-fill-available;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    padding: 30px;
    position: absolute;
    transition: all .5s;
    z-index: -1;
    border-radius: 0 0 10px 10px;
    bottom: 0;
    text-align: justify;
    opacity: 0
}

.search-input--input .notfound.open {
    position: initial;
    opacity: 1
}

.search-input--input #notfound:target {
    bottom: -145px
}

.sec-heading {
    font-size: 1rem;
    letter-spacing: .1em;
    display: flex;
    align-items: center
}

.sec-heading.sec-heading--white {
    color: #fff
}

.sec-heading img {
    width: 1.875em;
    display: inline-block;
    margin-right: 10px
}

.sellflow__head {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .1em
}

.sellflow__head img {
    width: 42px;
    height: 29px;
    display: inline-block
}

.sellflow__anchores {
    display: flex;
    flex-wrap: wrap;
    gap: 9px
}

.sellflow__anchores--item {
    border-radius: 5px;
    background-color: #fff;
    width: calc(50% - 4.5px);
    height: 40px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    text-align: left;
    position: relative;
    padding-inline: 10px
}

.sellflow__anchores--item-num {
    color: #6eb92b;
    font-size: 13px;
    font-weight: 700;
    line-height: 1.4
}

.sellflow__anchores--item-txt {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 1.15;
    display: inline-block
}

.sellflow__anchores--item:after {
    content: "";
    display: block;
    position: absolute;
    background-image: url(/assets/renewal/smp/img/icon/circle-arrow_bottom_sm.svg);
    background-size: cover;
    width: 16px;
    height: 16px;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%)
}

.sellflow__linkbtn {
    background-color: #6eb92b;
    border-radius: 6px;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    color: #fff;
    height: 50px;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.5
}

.sellflow__linkbtn-icon {
    width: 6px;
    height: 11px
}

.sellflow .meritflex {
    display: flex;
    flex-flow: column;
    gap: 16px
}

.sellflow .meritflex a {
    border: 3px solid #d4d4d4;
    border-radius: 10px;
    position: relative;
    font-size: 14px;
    color: #6eb92b;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: underline;
    text-underline-offset: 2px;
    width: 100%;
    height: 60px
}

.sellflow .meritflex a span {
    color: #6eb92b;
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: 18px;
    position: absolute;
    background: #fff;
    transform: translate(0, -50%);
    top: 0;
    left: -3px;
    width: 27px;
    height: 24px;
    text-align: center
}

.sellflow .sellflow-pointgrid {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.sellflow .sellflow-pointgrid div {
    border: 3px solid #6eb92b;
    border-radius: 10px;
    display: flex;
    gap: 17px;
    padding: 16px 20px;
    align-items: center
}

.sellflow .sellflow-pointgrid div p:nth-of-type(1) {
    width: 68px;
    font-size: 13px;
    font-weight: bold;
    color: #6eb92b
}

.sellflow .sellflow-pointgrid div p:nth-of-type(1) span {
    display: block;
    font-size: 13px;
    font-family: "Roboto", "Noto Sans JP", sans-serif
}

.sellflow .sellflow-pointgrid div ul:nth-of-type(1) {
    display: flex;
    flex-flow: column;
    gap: 3px;
    font-size: 14px
}

.sellflow .sellflow-pointgrid div ul:nth-of-type(1) li {
    font-size: 13px
}

.sellflow .sellflow-pointgrid div ul:nth-of-type(1) li::before {
    content: "";
    background: #6eb92b;
    width: 6px;
    height: 6px;
    display: inline-block;
    border-radius: 10px;
    margin-right: 10px;
    transform: translateY(-2px)
}

.sellflow .sellflow-flowgrid {
    display: grid;
    grid-template-rows: 156px
}

.sellflow .sellflow-flowgrid .item {
    background: #f5f5f5;
    border-radius: 10px;
    position: relative;
    padding: 28px 25px 0
}

.sellflow .sellflow-flowgrid .item span {
    position: absolute;
    top: 0;
    left: 0;
    background: #6eb92b;
    color: #fff;
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: 12px;
    padding: 1px 12px;
    border-radius: 10px 0 10px 0
}

.sellflow .sellflow-flowgrid .item p:nth-of-type(1) {
    color: #6eb92b;
    font-size: 16px;
    font-weight: bold
}

.sellflow .sellflow-flowgrid .item p:nth-of-type(2) {
    font-size: 14px;
    color: #292929;
    margin-top: 10px
}

.sellflow .sellflow-flowgrid .bet {
    display: flex;
    align-items: center;
    justify-content: center
}

.sellflow .swiper-slide {
    border-radius: 10px;
    overflow: hidden
}

.sellflow .swiper-pagination {
    width: -moz-fit-content;
    width: fit-content;
    height: 10px;
    position: absolute;
    left: 50%;
    bottom: 6px;
    transform: translate(-50%, -50%);
    z-index: 3;
    text-align: center;
    background-color: #fff;
    padding-inline: 5px;
    border-radius: 16px
}

.sellflow .swiper-pagination span {
    width: 4px;
    height: 4px;
    display: inline-block;
    border-radius: 10px;
    margin-inline: 3px;
    margin-bottom: 9px
}

.sellflow .swiper-pagination-bullet {
    background: #ccc
}

.sellflow .swiper-pagination-bullet-active {
    background: #6eb92b
}

.sellflow-step {
    padding-block: 20px
}

.sellflow-step__desc {
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px
}

.sellflow-step__desc--list.grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr
}

.sellflow-step__desc--list.grid li {
    margin-top: 0
}

.sellflow-step__desc--list li {
    padding-left: 13px;
    position: relative;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .05em;
    line-height: 1.6;
    text-align: justify
}

.sellflow-step__desc--list li:before {
    content: "";
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    top: .8em;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 4px;
    background-color: #6eb92b
}

.sellflow-step__desc--list li:not(:first-child) {
    margin-top: 10px
}

.sellflow-step__desc--list li .small-list {
    transform: translateX(-11px);
    padding-left: 13px
}

.sellflow-step__desc--list li .small-list p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    position: relative;
    padding-left: 1em
}

.sellflow-step__desc--list li .small-list p:before {
    content: "・";
    top: .8em;
    left: 0;
    transform: translate(0, -50%);
    position: absolute
}

.sellflow-step__head {
    display: flex;
    gap: 14px;
    justify-content: space-between
}

.sellflow-step__head--left {
    width: 100%
}

.sellflow-step__head--thumb {
    width: 100%;
    height: auto;
    border-radius: 10px
}

.sellflow-step__ttl {
    display: flex;
    align-items: center;
    gap: 14px
}

.sellflow-step__ttl img {
    height: 41px;
    width: 40px;
    padding-right: 10px;
    border-right: 1px solid #6eb92b
}

.sellflow-step__ttl h2 {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: .1em;
    color: #6eb92b
}

.sellflow-step__catchbox {
    margin-top: 20px
}

.sellflow-step__catchbox--catch {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .1em
}

.sellflow-step__catchbox--catch.sm {
    font-size: 16px;
    letter-spacing: .05em
}

.sellflow-step__catchbox--message {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.7;
    text-align: justify
}

.sellflow-step__links {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.sellflow-step__links--link {
    background-color: #6eb92b;
    border-radius: 6px;
    display: flex;
    gap: 10px;
    align-items: center;
    color: #fff;
    padding: 10px 20px;
    height: 40px;
    border: 2px solid #6eb92b;
    transition: background-color .3s ease, color .3s ease;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.5
}

.sellflow-step__point {
    padding: 28px 18px 18px;
    border: 3px solid #6eb92b;
    border-radius: 10px
}

.sellflow-step__point--ttl {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .1em;
    color: #6eb92b
}

.sellflow-step__point--ttl-icon {
    width: 44px;
    height: 44px;
    display: inline-block
}

.sellflow-step__point--list.grid {
    display: grid;
    gap: 20px;
    grid-template-columns: 1fr 1fr
}

.sellflow-step__point--list.grid li {
    margin-top: 0
}

.sellflow-step__point--list li {
    padding-left: 12px;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.6;
    text-align: justify
}

.sellflow-step__point--list li:before {
    content: "";
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    top: .8em;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 4px;
    background-color: #6eb92b
}

.sellflow-step__point--list li:not(:first-child) {
    margin-top: 10px
}

.sellflow-step__point--list li .small-list {
    transform: translateX(-11px);
    padding-left: 13px
}

.sellflow-step__point--list li .small-list p {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    position: relative;
    padding-left: 1em
}

.sellflow-step__point--list li .small-list p:before {
    content: "・";
    top: .8em;
    left: 0;
    transform: translate(0, -50%);
    position: absolute
}

.sellflow-step__flex01 {
    display: flex;
    gap: 50px
}

.sellflow-step__flex01--text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.7;
    margin-top: 13px;
    text-align: justify
}

.sellflow-step__flex01 img {
    width: 430px
}

.sellflow-step__table {
    border: 2px solid #f5f5f5;
    border-bottom: none;
    display: flex;
    flex-flow: column
}

.sellflow-step__table--column {
    flex: 1
}

.sellflow-step__tableitem {
    display: flex
}

.sellflow-step__tableitem.type01 {
    height: auto
}

.sellflow-step__tableitem.type02 {
    height: auto
}

.sellflow-step__tableitem.type03 {
    height: auto
}

.sellflow-step__tableitem.type04 {
    height: auto
}

.sellflow-step__tableitem--ttl {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 60px;
    background-color: #e8f2df;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .04em;
    border-bottom: 2px solid #fff
}

.sellflow-step__tableitem--cont {
    padding: 11px 10px;
    flex: 1;
    display: grid;
    align-items: center;
    border-bottom: 2px solid #f5f5f5
}

.sellflow-step__tableitem--normal {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.5
}

.sellflow-step__tableitem--note {
    font-size: 10px;
    font-weight: 500;
    letter-spacing: .03em
}

.sellflow-step__tableitem--list li {
    padding-left: 15px;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.2
}

.sellflow-step__tableitem--list li:before {
    content: "";
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    top: .66em;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 4px;
    background-color: #6eb92b
}

.sellflow-step__tableitem--list li:not(:first-child) {
    margin-top: 10px
}

.sellflow-step__tableitem--list li span {
    display: block;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: .04em;
    margin-top: 5px
}

.sellflow-step__tableitem:last-child .sellflow-step__tableitem--ttl {
    border-bottom: 2px solid #f5f5f5
}

.sellflow-step__borderbox01 {
    border: 3px solid #6eb92b;
    border-radius: 10px
}

.sellflow-step__borderbox01--flex {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 10px;
    padding: 30px 20px
}

.sellflow-step__borderbox01--flex.gap60 {
    gap: 60px
}

.sellflow-step__borderbox01--flex p {
    font-size: 12px;
    font-weight: 700;
    letter-spacing: .1em
}

.sellflow-step__borderbox02 {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 30px 20px;
    line-height: 1.6
}

.sellflow-step__borderbox02 .border-bottom {
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 30px
}

.sellflow-step__borderbox02--ttl {
    color: #6eb92b;
    font-size: 15px;
    font-weight: 700;
    letter-spacing: .04em
}

.sellflow-step__borderbox02--text {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .04em
}

.sellflow-step__borderbox02--check {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.sellflow-step__borderbox02--check li {
    display: flex;
    gap: 10px
}

.sellflow-step__borderbox02--check li p {
    flex: 1;
    font-size: 13px;
    font-weight: 700
}

.sellflow-step__borderbox02--check li span {
    display: inline-block;
    width: 18px;
    height: 18px;
    border: 2px solid #dbdbdb;
    background-color: #fff;
    border-radius: 3px
}

.sellflow-step__flow {
    display: flex;
    flex-flow: column;
    align-items: center;
    gap: 5px
}

.sellflow-step__flowitem {
    position: relative;
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 26px 20px;
    width: 100%
}

.sellflow-step__flowitem-arrow {
    width: 26px;
    height: 10px
}

.sellflow-step__flowitem--num {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #6eb92b;
    color: #fff;
    border-radius: 10px 0 10px 0;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0;
    line-height: 20px;
    padding: 0 14.5px
}

.sellflow-step__flowitem--ttl {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: .05em;
    margin-bottom: 10px;
    color: #6eb92b
}

.sellflow-step__flowitem--text {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    text-align: justify
}

.sellflow-step__prepare {
    border-radius: 10px;
    background-color: #e8f2df;
    padding: 20px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    gap: 20px
}

.sellflow-step__prepare--ttl {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #538b21;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.42
}

.sellflow-step__prepare--ttl img {
    width: 30px;
    height: 30px
}

.sellflow-step__prepare--list {
    align-self: flex-start
}

.sellflow-step__prepare--list li {
    padding-left: 13px;
    position: relative;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.5
}

.sellflow-step__prepare--list li:before {
    content: "";
    position: absolute;
    display: block;
    width: 6px;
    height: 6px;
    top: .7em;
    left: 0;
    transform: translate(0, -50%);
    border-radius: 4px;
    background-color: #6eb92b
}

.sellflow-step__prepare--list li:not(:first-child) {
    margin-top: 10px
}

.sellflow-step__flex02 {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.sellflow-step__flex02--item {
    border-radius: 10px;
    border: 3px solid #6eb92b;
    display: flex;
    align-items: center;
    padding: 16px 0;
    flex: 1
}

.sellflow-step__flex02--ttl {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.375em;
    color: #6eb92b;
    text-align: center;
    padding: 0 20px
}

.sellflow-step__flex02--list {
    flex: 1;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    padding-left: 20px;
    border-left: 2px solid #f0f0f0
}

.sellflow-step__flex03 {
    display: flex;
    flex-flow: column;
    gap: 10px
}

.sellflow-step__flex03--item {
    border-radius: 10px;
    background-color: #f5f5f5;
    padding: 20px;
    flex: 1
}

.sellflow-step__flex03--ttl {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .05em;
    line-height: 1.375em;
    color: #6eb92b;
    margin-bottom: 10px
}

.sellflow-step__flex03--list {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    padding-left: 20px
}

.sellflow-step__flowcolumn3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 62px 48px
}

.sellflow-step__flowcolumn3 .sellflow-step__flowitem:after {
    display: none
}

.sellflow-step__flowcolumn3 .sellflow-step__flowitem:not(:nth-child(3n)):after {
    display: block;
    right: -33px
}

.sellflow-step__flowcolumn3 .sellflow-step__flowitem:last-child:after {
    display: none
}

.sellflow-step__flowcolumn3 .sellflow-step__flowitem:nth-child(3):before {
    content: url(/assets/renewal/smp/img/icon/deco/flow-rows-arrow.svg);
    right: 50%;
    bottom: -9px;
    transform: translate(0, 100%);
    position: absolute
}

.sellflow-step__bigarrow {
    width: 35px;
    margin-inline: auto
}

.site-links {
    display: flex;
    flex-wrap: wrap;
    gap: 20px 50px
}

.site-links__item {
    line-height: 1;
    font-size: .75rem;
    letter-spacing: 0;
    font-weight: 700;
    padding-right: 24px;
    display: flex;
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_right.svg);
    background-repeat: no-repeat;
    background-size: 6px 10px;
    background-position: center right;
    color: #4b4b4b
}

.site-links__item.site-links__item--tab {
    -moz-column-gap: 10px;
    column-gap: 10px
}

.site-links__item.site-links__item--tab::after {
    content: url(/assets/renewal/smp/img/icon/tab-link_green.svg);
    display: block;
    width: 12px;
    height: 12px
}

.site-ttl {
    margin-top: var(--header-height);
    background-color: #538b21;
    color: #fff;
    font-size: .6875rem;
    letter-spacing: .1em;
    text-align: center;
    padding-top: 5px;
    padding-bottom: 5px;
    font-weight: 700
}

.square-ttl {
    display: flex;
    align-items: center;
    font-size: .875rem
}

.square-ttl::before {
    content: "";
    display: inline-block;
    width: 4px;
    height: 4px;
    background-color: #6eb92b;
    margin-right: 6px
}

.step-content {
    width: 100%;
    height: auto;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    padding: 30px 20px 20px
}

.step-head {
    display: flex;
    flex-flow: column-reverse;
    width: 100%;
    height: auto;
    border-radius: 10px 10px 0 0
}

.step-head__left {
    display: flex;
    flex-flow: column;
    color: #fff;
    background-color: #6eb92b;
    width: 100%;
    padding: 20px
}

.step-head__left--subttl {
    display: flex;
    align-items: center;
    font-size: .75rem;
    font-weight: 700;
    margin-bottom: 5px
}

.step-head__left--subttl .slash {
    content: url(/assets/renewal/smp/img/icon/slash-white.svg);
    width: 5px;
    height: 9px;
    margin-inline: 5px
}

.step-head__left--subttl .num-lg {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: .75rem;
    letter-spacing: 0
}

.step-head__left--ttl {
    font-size: 1.5rem;
    font-weight: 700;
    margin-bottom: 16px;
    letter-spacing: .1em
}

.step-head__left--txt {
    font-size: .875rem;
    font-weight: 500;
    line-height: 1.64285714;
    letter-spacing: .04em
}

.step-head__right {
    width: 100%
}

.store-search {
    width: 100%;
    background-color: #6eb92b;
    padding: 20px;
    border-radius: 10px
}

.store-search-wrap {
    padding-inline: 20px
}

.store-search__txt {
    color: #fff;
    font-size: 14px;
    letter-spacing: .04em;
    line-height: 1.64285714
}

.support-fixed {
    position: fixed;
    bottom: 0;
    display: flex;
    gap: 4px;
    width: 100%;
    background-color: #fff;
    padding: 4px;
    z-index: 3;
    transition: .2s
}

.support-fixed-item {
	flex: 1;
	background-color: #6eb92b;
	border-radius: 4px;
	padding: 11px 4px;
	cursor: pointer
}

.support-fixed-item__link {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3px;
    width: 100%
}

.support-fixed-item__left {
    width: 20px;
    height: 20px
}

.support-fixed-item__right {
    color: #fff;
    font-size: 11px;
    line-height: 1.1818
}

.support-fixed.hide {
    opacity: 0;
    pointer-events: none
}

.support-kv {
    overflow: hidden;
    position: relative;
    height: 220px
}

.support-kv__bg {
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%)
}

.support-kv__wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    padding-top: 51px
}

.support-kv__textbox {
    z-index: 1;
    color: #fff;
    font-weight: 700;
    text-shadow: 1px 1px 10px rgba(0, 0, 0, .3);
    position: inherit
}

.support-kv__textbox-ttl {
    font-size: 20px;
    letter-spacing: .1em;
    white-space: nowrap
}

.support-kv__textbox-txt {
    font-size: 14px;
    letter-spacing: .04em;
    line-height: 1.6428;
    white-space: nowrap
}

.support-kv__person {
    width: 470px;
    height: auto;
    align-self: flex-end;
    z-index: 1
}

.support__h2 {
    position: relative;
    color: #6eb92b;
    font-size: 16px;
    font-weight: 700;
    line-height: 1.35;
    letter-spacing: .1em;
    padding-left: 40px
}

.support__h2 img {
    position: absolute;
    top: 50%;
    left: 0;
    display: inline-block;
    width: 30px;
    height: 30px;
    transform: translateY(-50%)
}

.support__lead {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: .04em;
    line-height: 1.64;
    text-align: justify
}

.support__quick-search {
    padding: 20px;
    background-color: #6eb92b;
    border-radius: 0 0 10px 10px
}

.support__quick-search--ttl {
    display: flex;
    flex-flow: column
}

.support__quick-search--ttl p {
    display: flex;
    align-items: center;
    gap: 4px;
    color: #fff;
    font-size: 16px;
    letter-spacing: .1em
}

.support__quick-search--ttl p::before {
    display: inline-block;
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/search_white-big.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 22px;
    height: 22px
}

.support__quick-search--ttl span {
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: .04em;
    margin-top: 10px
}

.support .top-quick-search__word-area {
    position: relative;
    width: 100%
}

.support .top-quick-search__word-area input[type=text] {
    width: 100%;
    height: 50px;
    padding-left: 20px;
    border-radius: 5px
}

.support .top-quick-search__word-area .btn {
    position: absolute;
    top: 5px;
    right: 5px;
    color: #fff;
    background-color: #6eb92b;
    border-radius: 5px;
    width: 70px;
    height: 40px
}

.support__ranking--item a {
    position: relative;
    border-radius: 10px;
    background-color: #f5f5f5;
    padding-left: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px
}

.support__ranking--item a:after {
    position: absolute;
    right: 10px;
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/arrow_green_right.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 6px;
    height: 10px
}

.support__ranking--item:not(:first-child) {
    margin-top: 10px
}

.support__ranking--item p {
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0
}

.support__ranking--item p span {
    display: inline-block;
    margin-right: 10px;
    font-size: 16px;
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    letter-spacing: .01em;
    color: #6eb92b;
    line-height: 1;
    transform: translateY(0.04em)
}

.support__links {
    display: flex;
    flex-wrap: wrap;
    gap: 9px
}

.support__links--link {
    width: calc(50% - 4.5px);
    border-radius: 10px;
    aspect-ratio: 1;
    padding: 18px 0 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    background-color: #e8f2df;
    color: #538b21;
    font-size: 13px;
    font-weight: 700;
    letter-spacing: 0;
    text-align: center
}

.support__links--link div {
    display: flex;
    align-items: center
}

.support__links--link div p {
    display: flex;
    align-items: center;
    gap: 5px
}

.support__links--link div p:after {
    display: inline-block;
    content: "";
    background-image: url(/assets/renewal/smp/img/icon/circle-arrow-right02.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px
}

.support__links--link svg {
    width: 60px;
    height: 60px
}

.support__links--link .stroke {
    stroke: #6eb92b
}

.support__links--link .fill {
    fill: #6eb92b
}

.support .qa .breadcrumb-list {
    padding-inline: 0 !important
}

.tab-list {
    width: 100%;
    border-radius: 10px
}

.tab-list__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px
}

.tab-list__menu-item {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #292929;
    font-size: 16px;
    font-weight: 700;
    width: calc(50% - 2.5px);
    height: 50px;
    background-color: #ccc;
    border-radius: 6px 6px 0 0;
    letter-spacing: .06em;
    cursor: pointer;
    transition: all .3s
}

.tab-list__menu-item.fs14 {
    font-size: .875rem;
    letter-spacing: .1em
}

.tab-list__menu-item.is-active {
    background-color: #6eb92b;
    color: #fff
}

.tab-list__panel {
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    border: 4px solid #6eb92b;
    padding: 16px
}

.tab-list__panel-box001 {
    overflow: hidden;
    height: 0;
    opacity: 0
}

.tab-list__panel-box002 {
    overflow: hidden;
    height: 0;
    opacity: 0
}

.tab-list__panel-box003 {
    overflow: hidden;
    height: 0;
    opacity: 0
}

.tab-list__panel-box.is-show {
    overflow: visible;
    height: auto;
    opacity: 1;
    transition: opacity .6s ease-in-out
}

.tag-list {
    display: flex;
    flex-wrap: wrap;
    gap: 10px
}

.tag-list__item a {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 1rem;
    font-weight: 700;
    width: -moz-fit-content;
    width: fit-content;
    line-height: 1.3;
    background-color: #fff;
    border-radius: 999px;
    padding-left: 20px;
    padding-right: 20px
}

.tag-list__item a::before {
    content: "#";
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    font-size: 1.25em;
    color: #6eb92b;
    margin-right: .15em
}

.textbox-gray {
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    text-align: center;
    display: inline-block;
    margin-inline: auto
}

.textbox-gray__ttl {
    font-size: .875rem;
    letter-spacing: .04em;
    font-weight: 700;
    color: #538b21
}

.textbox-gray__text {
    font-size: .6875rem;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.4545;
    text-align: left
}

.textbox-greenborder {
    border: 3px solid #6eb92b;
    border-radius: 10px;
    display: flex;
    align-items: flex-start;
    flex-flow: column;
    padding: 19px;
    gap: 10px
}

.textbox-greenborder__ttlbox {
    font-weight: 700;
    flex: 1
}

.textbox-greenborder__ttlbox-main {
    font-size: 1rem;
    letter-spacing: .1em;
    color: #6eb92b
}

.textbox-greenborder__ttlbox-sub {
    margin-top: 2px;
    font-size: 1rem;
    letter-spacing: .05em;
    color: #292929;
    font-weight: 700
}

.textbox-greenborder__desc {
    flex: 1;
    font-size: .875rem;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.64;
    text-align: justify;
    -moz-text-align-last: left;
    text-align-last: left
}

.textbox-greenborder__desc>span:last-child {
    display: block;
    text-align: left
}

.textbox-greenborder__desc:after {
    content: "";
    display: none;
    width: 100%
}

.textbox-greenborder__desc .normal-note {
    margin-top: 14px;
    display: inline-block
}

.top-prefecture__top {
    background-color: #6eb92b;
    padding-top: 30px;
    padding-bottom: 30px
}

.top-prefecture__btm {
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: justify;
    word-break: break-all;
    -webkit-hyphens: auto;
    hyphens: auto;
    background-color: #f5f5f5;
    line-height: 1.7
}

.top-prefecture__btm--white {
    font-size: 12px;
    line-height: 1.6666;
    background-color: #fff
}

.top-yt__container {
    padding-top: 21px
}

.top-yt__content {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    position: relative
}

.top-yt__content iframe {
    position: relative;
    z-index: 2;
    width: 100%;
    height: auto;
    aspect-ratio: 16/9
}

.top-yt__close {
    position: absolute;
    top: -60px;
    right: 0;
    z-index: 1
}

.top-yt__close img {
    width: 40px;
    height: 40px
}

.wreath-figure__top {
    padding: 0 25px
}

.wreath-figure__top-item {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #6eb92b;
    border-radius: 10px 10px 0 0;
    color: #fff;
    padding: 20px;
    text-align: center;
    flex: 1;
    font-size: .9375rem;
    font-weight: 700;
    letter-spacing: .1em;
    line-height: 1.333;
    width: 100%;
    height: 60px
}

.wreath-figure__cont {
    padding: 20px;
    background-color: #e8f2df;
    border-radius: 10px;
    text-align: center
}

.wreath-figure__flex {
    display: flex;
    flex-flow: column;
    gap: 20px
}

.wreath-figure__flex-item {
    background-color: #fff;
    border-radius: 0 0 10px 10px;
    color: #292929;
    padding: 20px;
    text-align: justify;
    flex: 1;
    font-size: .8125rem;
    font-weight: 500;
    letter-spacing: .04em;
    line-height: 1.6
}

.wreath-figure__flex-item span {
    display: block
}

.wreath-figure__flex-item .normal-note {
    font-size: .6875rem
}

.wreath-figure__figure {
    margin: auto
}

.container {
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto
}

.inner-container {
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto
}

.pt15 {
    padding-top: 15px !important
}

.pt20 {
    padding-top: 20px !important
}

.pt30 {
    padding-top: 30px !important
}

.pt40 {
    padding-top: 40px !important
}

.pt50 {
    padding-top: 40px !important
}

.pt60 {
    padding-top: 60px !important
}

.pt70 {
    padding-top: 70px !important
}

.pt80 {
    padding-top: 80px !important
}

.pt100 {
    padding-top: 100px !important
}

.pb0 {
    padding-bottom: 0px !important
}

.pb15 {
    padding-bottom: 15px !important
}

.pb20 {
    padding-bottom: 20px !important
}

.pb30 {
    padding-bottom: 30px !important
}

.pb40 {
    padding-bottom: 40px !important
}

.pb50 {
    padding-bottom: 50px !important
}

.pb60 {
    padding-bottom: 60px !important
}

.pb70 {
    padding-bottom: 70px !important
}

.pb80 {
    padding-bottom: 80px !important
}

.pb100 {
    padding-bottom: 100px !important
}

.p-inline {
    padding: 20px !important
}

.p20 {
    padding: 20px !important
}

.mt0 {
    margin-top: 0px !important
}

.mt3 {
    margin-top: 3px !important
}

.mt5 {
    margin-top: 5px !important
}

.mt10 {
    margin-top: 10px !important
}

.mt15 {
    margin-top: 15px !important
}

.mt16 {
    margin-top: 16px !important
}

.mt20 {
    margin-top: 20px !important
}

.mt25 {
    margin-top: 25px !important
}

.mt30 {
    margin-top: 30px !important
}

.mt-30 {
    margin-top: -30px !important
}

.mt35 {
    margin-top: 35px !important
}

.mt40 {
    margin-top: 40px !important
}

.mt45 {
    margin-top: 45px !important
}

.mt50 {
    margin-top: 50px !important
}

.mt-50 {
    margin-top: -50px !important
}

.mt54 {
    margin-top: 54px !important
}

.mt60 {
    margin-top: 60px !important
}

.mt70 {
    margin-top: 70px !important
}

.mt80 {
    margin-top: 80px !important
}

.mt100 {
    margin-top: 100px !important
}

.mt-217 {
    margin-top: -217px !important
}

.mr20 {
    margin-right: 20px
}

.ml40 {
    margin-left: 40px
}

.ml-auto {
    margin-left: auto
}

.mi-auto {
    margin-inline: auto
}

.mb20 {
    margin-bottom: 20px !important
}

.inline-block {
    display: inline-block
}

.inline {
    display: inline
}

.sr-only {
    border: 0 !important;
    clip: rect(0 0 0 0) !important;
    -webkit-clip-path: inset(50%) !important;
    clip-path: inset(50%) !important;
    height: 1px !important;
    margin: -1px !important;
    overflow: hidden !important;
    padding: 0 !important;
    position: absolute !important;
    white-space: nowrap !important;
    width: 1px !important
}

.fz-16 {
    font-size: 1rem
}

.letter-sm {
    letter-spacing: .04em !important
}

.letter-md {
    letter-spacing: .1em !important
}

.letter-xl {
    letter-spacing: .4em !important;
    margin-left: .5em
}

.txt-justify {
    text-align: justify;
    word-break: break-all;
    -webkit-hyphens: auto;
    hyphens: auto
}

.txt-center {
    text-align: center
}

.txt-right {
    text-align: right
}

.ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.r-md {
    border-radius: 10px
}

.r-b-md {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px
}

.full-width {
    width: 100% !important
}

.full-height {
    height: 100% !important
}

.roboto {
    font-family: "Roboto", sans-serif
}

.txt-line {
    text-decoration: underline
}

.bg-gray01 {
    background-color: #f5f5f5
}

.bg-gray02 {
    background-color: #f0f0f0
}

.bg-gray03 {
    background-color: #e5e5e5
}

.bg-dark-gray {
    background-color: #616161
}

.bg-white {
    background-color: #fff
}

.bg-green {
    background-color: #6eb92b
}

.bg-light-green {
    background-color: #e8f2df
}

.border-btm {
    border-bottom: 2px solid #f5f5f5
}

.c-white {
    color: #fff
}

.tab {
    display: inline-block;
    background-repeat: no-repeat;
    padding-right: 17px;
    background-image: url(/assets/renewal/smp/img/icon/tab.svg);
    background-size: 12px 12px;
    background-position: right center
}

.tab.tab--green {
    background-image: url(/assets/renewal/smp/img/icon/tab-green.svg);
}

.inline-accent-link {
    color: #f28f08;
    text-decoration: underline;
    text-underline-offset: 2px
}

[data-focus-effect-wrap] {
    transition: box-shadow .3s, transform .3s
}

[data-focus-effect-wrap].focus {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .3)
}

[data-focus-effect] {
    transition: box-shadow .3s, transform .3s
}

[data-focus-effect].focus-input {
    box-shadow: 0 3px 6px rgba(0, 0, 0, .3)
}

.flex-column {
    display: flex;
    flex-flow: column
}

.flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.flex-direction {
    flex-direction: row-reverse
}

.map-link {
    font-family: "Roboto", "Noto Sans JP", sans-serif;
    text-decoration: none !important
}

.map-link .txt {
    text-decoration: underline;
    text-underline-offset: 2px;
    transition: color .3s
}

.map-link .badge {
    vertical-align: middle;
    padding-left: 17px;
    padding-right: 8px;
    display: inline-block;
    font-size: .6875rem;
    font-weight: 700;
    letter-spacing: .05em;
    background-color: #6eb92b;
    color: #fff;
    line-height: 20px;
    width: 50px;
    border-radius: 3px;
    background-image: url(/assets/renewal/smp/img/icon/map-point_white.svg);
    background-size: 10px 10px;
    background-repeat: no-repeat;
    background-position: left 5px center;
    transition: background-color .3s
}

.over-hidden {
    overflow: hidden
}

.u-judge-active {
    display: none
}

.u-judge-active.active {
    display: block
}

.u-textlink01 {
    color: #6eb92b;
    text-decoration: underline;
    text-underline-offset: 2px
}

.two-char {
    letter-spacing: .4em !important
}

.col-2 {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, 1fr)
}

.col-md-2 {
    display: grid;
    gap: 10px
}

@media(min-width: 736px) {
    .col-md-2 {
        grid-template-columns: repeat(2, 1fr)
    }
}

.col-md-4 {
    display: grid;
    gap: 10px
}

@media(min-width: 736px) {
    .col-md-4 {
        grid-template-columns: repeat(4, 1fr)
    }
}

.banner {
    position: relative;
    border-radius: 5px;
    z-index: 1;
    isolation: isolate
}

.banner a {
    display: block
}

.banner::after {
    transition: background-color .3s;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    display: block;
    content: "";
    width: 100%;
    height: 100%;
    background-color: rgba(41, 41, 41, 0);
    border-radius: 5px;
    pointer-events: none
}

.banner:active::after {
    background-color: rgba(41, 41, 41, .16)
}

.z-index2 {
    position: relative;
    z-index: 2
}